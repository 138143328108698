import * as React from "react"
import {useEffect,useState} from "react"
import './Mutate.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate } from "react-router-dom";
import { ethers } from 'ethers'
import axios from "axios";
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import editIcon from '../../assets/images/edit.png'
import optionIcon from '../../assets/images/option.png'
import { HmacSHA256, enc } from 'crypto-js';
//import allow json file from helper folder
const allow = require('../../helper/allow.json')
const marketplaceAbi = require('../../helper/marketplace.json')


const mutantbananaAbi = require('../../helper/mutantBanana.json')
const whitelist = require('../../helper/whitelist.json')
const bananaAbi = require('../../helper/bananaAbi.json')
const stakeAbi = require('../../helper/stakeAbi.json')
const spkAbi = require('../../helper/spk.json')

function Mint() {
  const [loadedAddr, setLoadedAddr] = useState(false);
    let navigate = useNavigate()
    const base_uri = "https://api.supremekong.com";
    //const base_uri = "http://localhost:8001";
    const marketplaceAddress = "0xbc42459387d1598DFb80753adEeb82609c697D89"
    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const [mintVisible, setMintVisible] = React.useState(true);

    const countDownDate = new Date("2022-10-01T13:00:00.000-04:00").getTime();
    
    const [title,setTitle] = React.useState('')
    const [img,setImg] = React.useState('')
    const [file,setFile] = React.useState('')
    const [desc,setDesc] = React.useState('')
    const [price,setPrice] = React.useState('')
    const [minholding,setMinHolding] = React.useState('')
    const [mkId,setMkId] = React.useState('')
    const [fp,setFp] = React.useState('')
    const [supply,setSupply] = React.useState('')
    const [max,setMax] = React.useState('')
    const [slug,setSlug] = React.useState('')
    const [chain,setChain] = React.useState('')
    const [contract,setContract] = React.useState('')
    const [hide,setHide] = React.useState(0)
    const [staticPrice,setStaticPrice] = React.useState(0)
    const [id,setId] = React.useState('')

    const [loaded,setLoaded] = React.useState(false)

    const [marketplace, setMarketplace] = React.useState([])

    const [Listing, setListing] = React.useState([]);

    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    useEffect(() => {
      console.log('addr',address)
      if (address && address.trim() !== '' && address !== null && address !== undefined) {
        setLoadedAddr(true);
        loadListing()
      }
    }, [address]);

    useEffect(() => {
      console.log('addr',address)
      console.log('addr',loadedAddr)
      const timeoutId = setTimeout(() => {
        if (!loadedAddr) {
          localStorage.setItem('dest', '/');
          navigate('/connect');
        }
      }, 2000);
  
      return () => {
        clearTimeout(timeoutId); // Clear the timeout if the component unmounts or if 'loaded' becomes true
      };
    }, [loadedAddr]);
  
    useEffect(() => {
      if (loadedAddr && address && !allow.includes(address)) {
        localStorage.setItem('dest', '/');
        navigate('/connect');
      }
    }, [loadedAddr, address]);
    
  const loadModal = (obj) => {
    console.log("in")
    console.log(obj)
    setImg(obj.image_url)
    setTitle(obj.title)
    setDesc(obj.description)
    setMax(obj.max_wallet)
    setChain(obj.ticker)
    setPrice(obj.priceInEth)
    setSlug(obj.slug)
    setSupply(obj.supply)
    setContract(obj.contract_address)
    setId(obj.id)
    setStaticPrice(obj.static_price)
    setHide(obj.hide)
    setFp(obj.fp)
  }

  const resetModal = () => {
    setImg("")
    setTitle("")
    setDesc("")
    setMax("")
    setChain("")
    setPrice("")
    setSlug("")
    setSupply("")
    setContract("")
    setHide(0)
    setStaticPrice(0)
    setFp(0)
  }


  const handleUpload = async (e) => {
  
    
  };  


  const loadListing = async() => {
    try{
    const query = `
      {
        itemCreateds(first: 100) {
          id
          itemId
          name
          price
          marketplaceId
        }
      }
      `;
    const listing = await axios.post('https://api.studio.thegraph.com/query/46966/kong-mkt-mainnet/version/latest', {query: query})

    let listingData = listing.data.data.itemCreateds;



    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);
    
    console.log(listingData)
    const listingPromises = listingData.map(async (listing) => {
      const listingInfo = await contract.getItemListing(listing.itemId);
      

      const priceInEther = ethers.utils.formatEther(listingInfo[2]);

      listing.priceDisplay = parseFloat(priceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
      listing.supply = listingInfo[4].toNumber();
      listing.price = listingInfo[2];
      listing.priceInEth = Math.floor(priceInEther);
      listing.minholding = listingInfo[3];
      listing.image = "https://assets.supremekong.com/images/marketplace/"+ listing.marketplaceId +".png";
      listing.amount = 0;
      return listing;
    });
  
    // Wait for all metadata requests to complete
    listingData = await Promise.all(listingPromises);
    
    console.log(listingData)
    setMarketplace(listingData)

    }catch(err){
      console.log(err)
    }
  }

  const editSupply = async (id) => {

    try{
      console.log(id)
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);

      const tx = await contract.updateSuppply(id,supply)
      await tx.wait()
      toast.success('Transaction Success')

      loadListing()
    }catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }

  const editPrice = async (id) => {

    try{
      console.log(id)
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);


      
      const priceInWei = ethers.utils.parseEther(price.toString());

      const tx = await contract.updatePrice(id,priceInWei)
      await tx.wait()
      toast.success('Transaction Success')

      loadListing()
    }catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }


  const createItem = async () => {

    try{
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);

      const priceInWei = ethers.utils.parseEther(price.toString());

      const tx = await contract.createItem(title,mkId,priceInWei,minholding,supply)
      await tx.wait()
      toast.success('Transaction Success')

      loadListing()
    }catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }

    return (

      <>
      <Preloader handleLoad={handleLoad} />
      <Header siteTitle={`Title`} />
      <div
      id='Mint' className='Mint'>
        { mintVisible &&
        <div className='Mint__content'>
          {/* <motion.h1 
          {...paragraphAnimation}
          className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
          <div className='flex-container'>
            
            <div className='flex-item-right' style={{color:'#F2B90C'}}>
            <div style={{display:'flex',flexDirection:"row",marginBottom:'25px'}}>
              <p>MARKETPLACE</p>
              <div className="staking-kong-stakingbutton" style={{display:'flex',marginLeft:'auto',gap:'10px'}}>       
              <Popup
                      trigger={<button className="adminBtn">Reset All Limit</button>}
                      modal
                      nested
                    >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                      </div>
                    )}
                  </Popup>
                    

                    <Popup
                      trigger={<button className="adminBtn"><p onClick={() => { resetModal()}}>Add Item</p></button>}
                      modal
                      nested
                      >
                      {close => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <div className="header"> ADD ITEM </div>
                          <div className="content">
                            <div style={{marginBottom:'10px'}}>
                              <p>name</p>
                              <input type="text" placeholder="Input item name" className="input-modal" value={title} onChange={(e)=>{setTitle(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>Marketplace Id </p>
                              <input type="text" placeholder="Input marketplace id" className="input-modal" value={mkId} onChange={(e)=>{setMkId(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>price {"{$JNGL}"}</p>
                              <input type="text" placeholder="Input item price" className="input-modal" value={price} onChange={(e)=>{setPrice(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>min Holding {"{$JNGL}"}</p>
                              <input type="text" placeholder="Input min holding" className="input-modal" value={minholding} onChange={(e)=>{setMinHolding(e.target.value)}}></input>
                            </div>

                            <div style={{marginBottom:'10px'}}>
                              <p>supply</p>
                              <input type="text" placeholder="Input item supply" className="input-modal" value={supply} onChange={(e)=>{setSupply(e.target.value)}}></input>
                            </div>
                            {/* <div style={{marginBottom:'10px'}}>
                              <p>Hide</p>
                              <input type="checkbox" className="input-modal" id="hide" name="hide" value={hide} defaultChecked={hide} onChange={() => setHide(!hide)} style={{width:'5%'}}/>
                            </div> */}
                            <div style={{marginTop:'20px',display:'flex',placeContent:'center'}}>
                              <button style={{padding:'20px'}} className="DiscordButton" onClick={ ()=>{createItem();close()}}> ADD ITEM</button>
                            </div>
                          </div>
                        
                        </div>
                      )}
                    </Popup>
              </div>
            </div>

            {marketplace.map((object, i) => 

            <div className="marketplace-card" key={i}>
            <div className="marketplace-card-flex">
            { object.id == 4 ?
                <div className="marketplace-card-flex-left">
                    <video autoPlay loop playsInline muted src="https://assets.supremekong.com/bananas/banana.mp4"></video>
                </div>
                :
                <div className="marketplace-card-flex-left">
                    <img src={object.image}></img>
                </div>
            }

            <div className="marketplace-card-flex-right">
                <div className="title"> 
                    <p>{ object.name}  </p>
                    <div className="staking-kong-stakingbutton" style={{display:'flex',marginLeft:'auto',gap:'10px'}}>       
                    <Popup
                      trigger={<div className="adminRoundIcon"> <button className="adminRoundIcon" onClick={() => loadModal(object)} ><img src={editIcon} style={{width:'65%'}}></img></button></div>}
                      modal
                      nested
                      >
                      {close => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <div className="header"> Edit ITEM </div>
                          <div className="content">

                            <div style={{marginBottom:'10px'}}>
                              <p>supply</p>
                              <input type="text" placeholder="Input item supply" className="input-modal" value={supply} onChange={(e)=>{setSupply(e.target.value)}}></input>
                            </div>

                            <div style={{marginBottom:'10px'}}>
                              <p>price</p>
                              <input type="text" placeholder="Input item Price" className="input-modal" value={price} onChange={(e)=>{setPrice(e.target.value)}}></input>
                            </div>
                          </div>
                          <div style={{marginTop:'20px',display:'flex',placeContent:'center'}}>
                              <button style={{padding:'20px'}} className="DiscordButton" onClick={ ()=>{editSupply(object.itemId,supply);close()}}> EDIT SUPPLY</button>
                          </div>
                          <div style={{marginTop:'20px',display:'flex',placeContent:'center'}}>
                              <button style={{padding:'20px'}} className="DiscordButton" onClick={ ()=>{editPrice(object.itemId,price);close()}}> EDIT PRICE</button>
                          </div>
                        </div>
                      )}
                    </Popup>
              </div>
                </div>
                <div className="desc"> {object.description} </div>
                <div style={{display:'flex',gap:'10px',flexWrap:'wrap'}}>
                  <div style={{}}>
                    <b style={{fontSize:'10px'}}>Price </b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                            {object.priceDisplay} $JNGL
                        </div>
                    </div>
                  </div>

                  <div>
                    <b style={{fontSize:'10px'}}>Supply</b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                            {object.supply.toLocaleString()} 
                        </div>
                    </div>
                  </div>

                </div>
                  
              </div>

              </div>
              </div>
                
                )}

            </div>
          </div>
        </div>
        }
      </div>
      {/* { showModal &&
        <div className='modal-overlay'>
              <div className='modal-title'>
                <a href='https://opensea.io/collection/supremebananas'>
                  <DiscordButton onClick={ ()=>{}} href='' text='BUY ON OPENSEA' blank />
                </a>
              </div>
              
        </div>
        
      } */}
    </>
    );
}

export default Mint;
