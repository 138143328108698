import React from 'react';

import DiscordIcon from '../../../assets/icons/discord.svg';
import './DiscordButton.scss';

function DiscordButton({ text = 'Buy on OpenSea', href='https://opensea.io/collection/supremekong', blank }) {
  return (
    <button className='DiscordButton'>
      <a className='DiscordButton__anchor' target={blank ? '_blank' : '_self'} href={href} rel="noreferrer">
        {/*<div className='DiscordButton__image'>*/}
        {/*  <img src={DiscordIcon} alt='Discord Icon' />*/}
        {/*</div>*/}
        {text}
      </a>
    </button>
  );
}
export default DiscordButton;
