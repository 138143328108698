import * as React from "react"
import {useEffect, useState} from "react"

import './Airdrop.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate, useParams } from "react-router-dom";
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import axios from "axios";
import mutantbanana from '../../assets/images/mutantbanana.png'
import arrow from '../../assets/images/arrow.png'
import ProgressBar from "../../components/Progressbar/Progressbars";
import { fail } from "assert";
import { HmacSHA256, enc } from 'crypto-js';
import Popup from 'reactjs-popup';
import { send } from "process";
const allow = require('../../helper/allow.json')


const mutantbananaAbi = require('../../helper/mutantBanana.json')
const stakingAbi = require('../../helper/stakeAbi.json')
const bananaAbi = require('../../helper/bananaAbi.json')

let interval;

function Mint() {
    const [loadedAddr, setLoadedAddr] = useState(false);
    let navigate = useNavigate()

    let { id } = useParams();

    //const base_uri = "http://localhost:8001";
    const base_uri = "https://api.supremekong.com";
    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
    const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;
    const stakingContract = process.env.REACT_APP_STAKING_CONTRACT;

    const burnCost = 5;

    const [addressField, setAddress] = React.useState("")

    const [search, setSearch] = React.useState("")

    const [loaded, setLoaded] = React.useState(false)

    const [owner, setOwner] = React.useState(false)

    const [potion, setPotion] = React.useState(0)

    const [load, setLoad] = React.useState(false)

    const [count, setCount] = React.useState(0)

    const [level, setlevel] = React.useState(0)

    const [type, setType] = React.useState(1)

    const [eligible, setEligible] = React.useState(true)

    const [maxAmount, setMaxAmount] = React.useState(0)

    const [amount, setAmount] = React.useState(0);

    const [approvedBanana, setApprovedBanana] = React.useState(false)

    const [mintVisible, setMintVisible] = React.useState(true);

    const countDownDate = new Date("2022-08-31T22:00:00.000-04:00").getTime();
    const [showModal, setShowModal] = React.useState(true);

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    const [inventory, setInventory] = React.useState([])

    const [inventorys, setInventorys] = React.useState([])

    const [dropdown, setDropdown] = React.useState("1")

    const [items, setItems] = React.useState(false)

    const [nft, setNft] = React.useState(false)

    const [reedemnft, setReedemNft] = React.useState(false)

    const [day,setDay] = React.useState(0)
    const [hour,setHour] = React.useState(0)
    const [minute,setMinute] = React.useState(0)
    const [second,setSecond] = React.useState(0)

    const [marketId, setMarketId] = React.useState("")
    const [img, setImg] = React.useState("")
    const [title, setTitle] = React.useState("")
    const [description, setDescription] = React.useState("")


    const [sendAll,setSendAll] = React.useState(0)

    const handleLoad = React.useMemo(() => {
      if (address && address.trim() !== '' && address !== null && address !== undefined) {
        setLoaded(true);
      }
  }, [address]);

    //please create react useffect for this
    useEffect(() => {
      console.log('addr',address)
      if (address && address.trim() !== '' && address !== null && address !== undefined) {
        setLoadedAddr(true);
      }
    }, [address]);

    useEffect(() => {
      console.log('addr',address)
      console.log('addr',loadedAddr)
      const timeoutId = setTimeout(() => {
        if (!loadedAddr) {
          localStorage.setItem('dest', '/');
          navigate('/connect');
        }
      }, 2000);
  
      return () => {
        clearTimeout(timeoutId); // Clear the timeout if the component unmounts or if 'loaded' becomes true
      };
    }, [loadedAddr]);
  
    useEffect(() => {
      if (loadedAddr && address && !allow.includes(address)) {
        localStorage.setItem('dest', '/');
        navigate('/connect');
      }
    }, [loadedAddr, address]);

    const airdropToken = async(_id) =>{
      try{
        let now = Math.floor(Date.now() / 1000)
        const body = {
            address: addressField,
            amount: amount,
            timestamp: now
        }
        const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))
        const resp = await axios.post(base_uri+'/airDropBanana',
        body,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
            hash_key: hashkey
        }
        })
        toast.success("Success Airdrop $JNGL Token")
      }catch(err){
        toast.error("Fail Airdrop $JNGL Token")
      }
    }

    const airdropTokenAll = async(_id) =>{
      try{
        let now = Math.floor(Date.now() / 1000)
        const body = {
            address: addressField,
            amount: amount,
            timestamp: now
        }
        const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))
        const resp = await axios.post(base_uri+'/airDropBananatoAll',
        body,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
            hash_key: hashkey
        }
        })
        toast.success("Success Airdrop $JNGL Token")
      }catch(err){
        toast.error("Fail Airdrop $JNGL Token")
      }
    }

    const airdropPotion = async(_id) =>{
      try{
        let now = Math.floor(Date.now() / 1000)
        const body = {
            address: addressField,
            amount: amount,
            timestamp: now
        }
        const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))
        const resp = await axios.post(base_uri+'/airDropPotion',
        body,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
            hash_key: hashkey
        }
        })
        toast.success("Success Airdrop Leveling Potion")
      }catch(err){
        toast.error("Fail Airdrop Leveling Potion")
      }
    }

    const airdropPotionAll = async(_id) =>{
      try{
        let now = Math.floor(Date.now() / 1000)
        const body = {
            address: addressField,
            amount: amount,
            timestamp: now
        }
        const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))
        const resp = await axios.post(base_uri+'/airDropPotiontoAll',
        body,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
            hash_key: hashkey
        }
        })
        toast.success("Success Airdrop Leveling Potion")
      }catch(err){
        toast.error("Fail Airdrop Leveling Potion")
      }
    }

    const airdrop = () => {
      if(type == 1){
        if(sendAll == 0){
          airdropToken()
        }else{
          airdropTokenAll()
        }
      }else{
        if(sendAll == 0){
          airdropPotion()
        }else{
          airdropPotionAll()
        }
      }
    }

    const setTab = (index) =>{
        setSearch("")
        // setDropdown(index)
        // setInventory([])
       if(index == 1){
            setType(1)
            document.getElementById("tab-1").classList.remove('button-tab');
            document.getElementById("tab-1").classList.add('button-tab-active');
            document.getElementById("tab-2").classList.remove('button-tab-active');
            document.getElementById("tab-2").classList.remove('button-tab');
            document.getElementById("tab-2").classList.add('button-tab');
       }
       if(index == 2){
            setType(2)
            document.getElementById("tab-2").classList.remove('button-tab');
            document.getElementById("tab-2").classList.add('button-tab-active');
            document.getElementById("tab-1").classList.remove('button-tab-active');
            document.getElementById("tab-1").classList.remove('button-tab');
            document.getElementById("tab-1").classList.add('button-tab');
       }
      //  if(index == 3){
      //       setType(3)
      //       document.getElementById("tab-3").classList.remove('button-tab');
      //       document.getElementById("tab-3").classList.add('button-tab-active');
      //       document.getElementById("tab-2").classList.remove('button-tab-active');
      //       document.getElementById("tab-2").classList.remove('button-tab');
      //       document.getElementById("tab-2").classList.add('button-tab');
      //       document.getElementById("tab-1").classList.remove('button-tab-active');
      //       document.getElementById("tab-1").classList.remove('button-tab');
      //       document.getElementById("tab-1").classList.add('button-tab');
      //  }
    }

    



    return (
        <>
        <Preloader handleLoad={handleLoad} />
        <Header siteTitle={`Title`} />
        { loadedAddr &&
        <div className='Inventory__content'>
            <div className='container'>
                <p className="inventory-title">AIRDROP</p>
            </div>
            <div className="inventory-tab" >
                <button id="tab-1" className="button-tab-active" onClick={() => {setTab(1)}}>TOKENS</button>
                <button id="tab-2" className="button-tab" onClick={() => {setTab(2)}} >LEVELING POTIONS</button>
            </div>

            <div className="inventory-select">
                <select name="category" id="category" className="select-cat" value={dropdown} onChange={(e) => {setTab(e.target.value)}}>
                    <option value="1">TOKENS</option>
                    <option value="2">LEVELING POTION</option>
                </select>
            </div>

            <div className="inventory-container">
                <div className="inventory-left">
                    <div className="inventory-contents">
                        <div className="inventory-content">
                          { sendAll == 0   &&
                            <div style={{marginBottom:'10px',width:'100%'}}>
                              <p>wallet address</p>
                              <input type="text" placeholder="Input wallet address" className="input-modal" value={addressField} onChange={(e)=>{setAddress(e.target.value)}}></input>
                            </div>
                        }

                            <div style={{marginBottom:'10px'}}>
                              <p>Send to all</p>
                              <input type="checkbox" className="input-modal" id="sendAll" name="sendAll" value={sendAll} defaultChecked={sendAll} onChange={() => setSendAll(!sendAll)} style={{width:'25%'}}/>
                            </div>

                            <div style={{marginBottom:'10px',width:'100%'}}>
                              <p>amount</p>
                              <input style={{textAlign:'center',fontSize:'20px'}} type="number" placeholder="amount" className="input-modal" value={amount} onChange={(e)=>{setAmount(e.target.value)}}></input>
                            </div>
                            <div style={{marginTop:'20px',display:'flex',width:'100%'}}>
                              { type == 1 &&
                                //<button style={{width:'100%',justifyContent:'center'}} className="DiscordButton" onClick={ ()=>{airdrop()}}><div> SEND TOKENS</div></button>
                                <Popup
                                  trigger={ <button style={{width:'100%',justifyContent:'center'}} className="DiscordButton" onClick={ ()=>{}}><div> SEND TOKENS</div></button>}
                                  modal
                                  nested
                                >
                                  {close => (
                                    <div className="modal">
                                      <button className="close" onClick={close}>
                                        &times;
                                      </button>
                                      {sendAll == 0   &&
                                        <div className="header"> Send Tokens</div>
                                      }
                                      {sendAll == 1   &&
                                        <div className="header"> Send Tokens to All</div>
                                      }
                                      
                                      <div className="actions" style={{display:'flex',justifyContent:'center',gap:'20px'}}>
                                        <button style={{width:'40%',justifyContent:'center'}} className="DiscordButton" onClick={()=>{airdrop();close()}}> 
                                          Yes 
                                        </button>
                                        <button
                                          style={{width:'40%',justifyContent:'center'}} className="DiscordButton"
                                          onClick={() => {
                                            console.log('modal closed ');
                                            close();
                                          }}
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              }
                              { type == 2 &&
                                // <button style={{width:'100%',justifyContent:'center'}} className="DiscordButton" onClick={ ()=>{airdrop()}}><div> SEND LEVELING POTIONS</div></button>

                                
                                <Popup
                                  trigger={ <button style={{width:'100%',justifyContent:'center'}} className="DiscordButton" onClick={ ()=>{}}><div> SEND LEVELING POTIONS</div></button>}
                                  modal
                                  nested
                                >
                                  {close => (
                                    <div className="modal">
                                      <button className="close" onClick={close}>
                                        &times;
                                      </button>
                                      {sendAll == 0   &&
                                        <div className="header"> Send Leveling Potion</div>
                                      }
                                      {sendAll == 1   &&
                                        <div className="header"> Send Leveling Potion to All</div>
                                      }
                                      
                                      <div className="actions" style={{display:'flex',justifyContent:'center',gap:'20px'}}>
                                        <button style={{width:'40%',justifyContent:'center'}} className="DiscordButton" onClick={()=>{airdrop();close()}}> 
                                          Yes 
                                        </button>
                                        <button
                                          style={{width:'40%',justifyContent:'center'}} className="DiscordButton"
                                          onClick={() => {
                                            console.log('modal closed ');
                                            close();
                                          }}
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inventory-right">
                    { items &&
                        <div className="inventory-detail">
                            <img src={img}></img>
                            <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px",marginTop:"10px"}}>Owned : {amount}</p>
                            <h4 style={{marginBottom:'20px',color:'white'}}>{title}</h4>
                            <p style={{fontSize:'12px',color:"white",fontFamily:"Lato",marginBottom:"20px"}}>{description}</p>
                            { marketId == 2 &&
                                <a href='/staking'>
                                    <button className="DiscordButton">USE</button>
                                </a>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
        }
        </>
    );
}

export default Mint;
