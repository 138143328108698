import * as React from "react"
import './Mutate.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate } from "react-router-dom";
import { ethers } from 'ethers'
import axios from "axios";
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import editIcon from '../../assets/images/edit.png'
import optionIcon from '../../assets/images/option.png'
import plusIcon from '../../assets/icons/plus.svg'
import { HmacSHA256, enc } from 'crypto-js';
//import allow json file from helper folder
const allow = require('../../helper/allow.json')


const mutantbananaAbi = require('../../helper/mutantBanana.json')
const whitelist = require('../../helper/whitelist.json')
const bananaAbi = require('../../helper/bananaAbi.json')
const stakeAbi = require('../../helper/stakeAbi.json')
const spkAbi = require('../../helper/spk.json')

function Mint() {
    let navigate = useNavigate()
    const base_uri = "https://api.supremekong.com";
    //const base_uri = "http://localhost:8001";
    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const [mintVisible, setMintVisible] = React.useState(true);

    const countDownDate = new Date("2022-10-01T13:00:00.000-04:00").getTime();
    
    const [title,setTitle] = React.useState('')
    const [img,setImg] = React.useState('')
    const [file,setFile] = React.useState('')
    const [desc,setDesc] = React.useState('')
    const [price,setPrice] = React.useState('')
    const [fp,setFp] = React.useState('')
    const [supply,setSupply] = React.useState('')
    const [max,setMax] = React.useState('')
    const [slug,setSlug] = React.useState('')
    const [chain,setChain] = React.useState('')
    const [contract,setContract] = React.useState('')
    const [hide,setHide] = React.useState(0)
    const [staticPrice,setStaticPrice] = React.useState(0)
    const [id,setId] = React.useState('')

    const [loaded,setLoaded] = React.useState(false)

    const [marketplace, setMarketplace] = React.useState([])

    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(() => {
        loadMarketplace()
        // if(address != "0x0D4Ed5aAE3A7F7C053f8759433436a03F01c7A81"){
        //     
        // }
        if(!allow.includes(address)){
          localStorage.setItem('dest','/')
            navigate('/connect')
        }
    }, [])
    
  const loadModal = (obj) => {
    console.log("in")
    console.log(obj)
    setImg(obj.image_url)
    setTitle(obj.title)
    setDesc(obj.description)
    setMax(obj.max_wallet)
    setChain(obj.ticker)
    setPrice(obj.price)
    setSlug(obj.slug)
    setSupply(obj.supply)
    setContract(obj.contract_address)
    setId(obj.id)
    setStaticPrice(obj.static_price)
    setHide(obj.hide)
    setFp(obj.fp)
  }

  const resetModal = () => {
    setImg("")
    setTitle("")
    setDesc("")
    setMax("")
    setChain("")
    setPrice("")
    setSlug("")
    setSupply("")
    setContract("")
    setHide(0)
    setStaticPrice(0)
    setFp(0)
  }

  const handleFileChange = async(e) => {
    console.log(e.target.files[0])
    const files = e.target.files[0];

    const formData = new FormData();
    console.log("file",file)
    formData.append('image', files);
  
    try {
      const response = await axios.post('https://api.supremekong.com/uploadImg', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m'
        }
      });
      const imageUrl = response.data.imageUrl;
      setImg(imageUrl)
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpload = async (e) => {
  
    
  };  

  const insertMarketplace = async() =>{
    try{
      let now = Math.floor(Date.now() / 1000)
      const body = {
          contract_address: contract,
          title: title,
          desc: desc,
          supply: supply,
          price: price,
          image_url: img,
          max_wallet: max,
          ticker: chain,
          slug: slug,
          static: staticPrice,
          hide: hide,
          fp: fp,
          timestamp: now
      }
      const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))
      const resp = await axios.post(base_uri+'/addItemMarket',
      body,
      {
      headers: {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
          hash_key: hashkey
      }
      })
      toast.success("Success Add Marketplace")
      loadMarketplace()
    }catch(err){
      toast.error("Fail Add Marketplace")
      loadMarketplace()
    }
  }

  const updateMarketplace = async() =>{
    try{
      let now = Math.floor(Date.now() / 1000)
      const body = {
          contract_address: contract,
          title: title,
          desc: desc,
          supply: supply,
          price: price,
          image_url: img,
          max_wallet: max,
          ticker: chain,
          slug: slug,
          static: staticPrice,
          hide: hide,
          id: id,
          fp: fp,
          timestamp: now
      }
      const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))
      const resp = await axios.post(base_uri+'/editItemMarket',
      body,
      {
      headers: {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
          hash_key: hashkey
      }
      })
      toast.success("Success Update Marketplace")
      loadMarketplace()
    }catch(err){
      toast.error("Fail Update Marketplace")
      loadMarketplace()
    }
  }

  const deleteMarketplace = async(_id) =>{
    try{
      let now = Math.floor(Date.now() / 1000)
      const body = {
          id: _id,
          timestamp: now
      }
      const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))
      const resp = await axios.post(base_uri+'/deleteItemMarket',
      body,
      {
      headers: {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
          hash_key: hashkey
      }
      })
      toast.success("Success Delete Marketplace")
      loadMarketplace()
    }catch(err){
      toast.error("Fail Update Marketplace")
      loadMarketplace()
    }
  }

  const resetMarketplace = async(_id) =>{
    try{
      let now = Math.floor(Date.now() / 1000)
      const body = {
          id: _id,
          timestamp: now
      }
      const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))
      const resp = await axios.post(base_uri+'/resetLimitMarket',
      body,
      {
      headers: {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
          hash_key: hashkey
      }
      })
      toast.success("Success Reset Marketplace")
      loadMarketplace()
    }catch(err){
      toast.error("Fail Reset Marketplace")
      loadMarketplace()
    }
  }

  const resetAll = async() =>{
    try{
      const resp = await axios.get(base_uri+'/resetLimit',
      {
      headers: {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
      }
      })
      toast.success("Success Reset All Marketplace")
      loadMarketplace()
    }catch(err){
      toast.error("Fail Reset All Marketplace")
      loadMarketplace()
    }
  }

  const updateMarketplaceData = async() => {
    try{
      const res = await axios.get(base_uri+'/updateMarketplace',
      {
      headers: {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
      }
      })
      toast.success("Success Update Marketplace")
    }catch(e){
      toast.error("Fail Update Marketplace")
    }
  }


  const loadMarketplace = async() => {
    const res = await axios.get(base_uri+'/getMarketplaceAdmin',
    {
    headers: {
        api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
    }
    })
    let marketplaceData = res.data.result
    setMarketplace(marketplaceData)
  }

    return (

      <>
      <Preloader handleLoad={handleLoad} />
      <Header siteTitle={`Title`} />
      <div
      id='Mint' className='Mint'>
        { mintVisible &&
        <div className='Mint__content'>
          {/* <motion.h1 
          {...paragraphAnimation}
          className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
          <div className='flex-container'>
            
            <div className='flex-item-right' style={{color:'#F2B90C'}}>
            <div style={{display:'flex',flexDirection:"row",marginBottom:'25px'}}>
              <p>MARKETPLACE</p>
              <div className="staking-kong-stakingbutton" style={{display:'flex',marginLeft:'auto',gap:'10px'}}>       
              <button className="adminBtn" onClick={updateMarketplaceData}>Update MP Assets</button>
              <Popup
                      trigger={<button className="adminBtn">Reset All Limit</button>}
                      modal
                      nested
                    >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                          <div className="header"> Reset All Limit</div>
                        <div className="actions" style={{display:'flex',justifyContent:'center',gap:'20px'}}>
                          <button style={{width:'40%',justifyContent:'center'}} className="DiscordButton" onClick={()=>{resetAll()}}> 
                            Yes 
                          </button>
                          <button
                            style={{width:'40%',justifyContent:'center'}} className="DiscordButton"
                            onClick={() => {
                              console.log('modal closed ');
                              close();
                            }}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}
                  </Popup>

                    <Popup
                      trigger={<button className="adminBtn"><p onClick={() => { resetModal()}}>Add Item</p></button>}
                      modal
                      nested
                      >
                      {close => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <div className="header"> ADD ITEM </div>
                          <div className="content">
                            <div style={{marginBottom:'10px'}}>
                              <p>picture (Picture Format have to be in PNG)</p>
                              <input type="file" placeholder="Input item image url" className="input-modal" onChange={handleFileChange}></input>
                              { img != ""&&
                                <img src={img} width="300px" height="300px"></img>
                              }
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>name</p>
                              <input type="text" placeholder="Input item name" className="input-modal" value={title} onChange={(e)=>{setTitle(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>description</p>
                              <textarea rows="4" placeholder="Input item description" className="input-modal" value={desc} onChange={(e)=>{setDesc(e.target.value)}}></textarea>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>price {"{$KONG}"}</p>
                              <input type="text" placeholder="Input item price" className="input-modal" value={price} onChange={(e)=>{setPrice(e.target.value)}}></input>
                            </div>

                            <div style={{marginBottom:'10px'}}>
                              <p>max per wallet</p>
                              <input type="text" placeholder="Input item max" className="input-modal" value={max} onChange={(e)=>{setMax(e.target.value)}}></input>
                            </div>
                            
                            <div style={{marginBottom:'10px'}}>
                              <p>blockchain</p>
                              <select placeholder="Input item max" className="input-modal" value={chain} onChange={(e)=>{setChain(e.target.value)}}>
                                <option value="-">NON NFT</option>
                                <option value="eth">ETHEREUM</option>
                                <option value="sol">SOLANA</option>
                              </select>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>contract address</p>
                              <input type="text" placeholder="Input item contract address" className="input-modal" value={contract} onChange={(e)=>{setContract(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>slug</p>
                              <input type="text" placeholder="Input item slug" className="input-modal" value={slug} onChange={(e)=>{setSlug(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>floor price</p>
                              <input type="text" placeholder="Input item price" className="input-modal" value={fp} onChange={(e)=>{setFp(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>Static Price</p>
                              <input type="checkbox" className="input-modal" id="static" name="static" value={staticPrice} defaultChecked={staticPrice} onChange={() => setStaticPrice(!staticPrice)} style={{width:'5%'}}/>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>Hide</p>
                              <input type="checkbox" className="input-modal" id="hide" name="hide" value={hide} defaultChecked={hide} onChange={() => setHide(!hide)} style={{width:'5%'}}/>
                            </div>
                            <div style={{marginTop:'20px',display:'flex',placeContent:'center'}}>
                              <button style={{padding:'20px'}} className="DiscordButton" onClick={ ()=>{insertMarketplace();close()}}> ADD ITEM</button>
                            </div>
                          </div>
                        
                        </div>
                      )}
                    </Popup>
              </div>
            </div>

            {marketplace.map((object, i) => 

            <div className="marketplace-card" key={i}>
            <div className="marketplace-card-flex">

            { object.id == 4 ?
                <div className="marketplace-card-flex-left">
                    <video autoPlay loop playsInline muted src="https://assets.supremekong.com/bananas/banana.mp4"></video>
                </div>
                :
                <div className="marketplace-card-flex-left">
                    <img src={object.image_url}></img>
                </div>
            }

            <div className="marketplace-card-flex-right">
                <div className="title"> 
                    <p>{object.id} - { object.title}  </p>
                    <div className="staking-kong-stakingbutton" style={{display:'flex',marginLeft:'auto',gap:'10px'}}>       
                    <Popup
                      trigger={<div className="adminRoundIcon"> <button className="adminRoundIcon" onClick={() => loadModal(object)} ><img src={editIcon} style={{width:'65%'}}></img></button></div>}
                      modal
                      nested
                      >
                      {close => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <div className="header"> Edit ITEM </div>
                          <div className="content">
                            <div style={{marginBottom:'10px'}}>
                              <p>picture (Picture Format have to be in PNG)</p>
                              <input type="file" placeholder="Input item image url" className="input-modal" onChange={handleFileChange}></input>
                              { img != ""&&
                                <img src={img} width="300px" height="300px"></img>
                              }
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>name</p>
                              <input type="text" placeholder="Input item name" className="input-modal" value={title} onChange={(e)=>{setTitle(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>description</p>
                              <textarea rows="4" placeholder="Input item description" className="input-modal" value={desc} onChange={(e)=>{setDesc(e.target.value)}}></textarea>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>price {"{$KONG}"}</p>
                              <input type="text" placeholder="Input item price" className="input-modal" value={price} onChange={(e)=>{setPrice(e.target.value)}}></input>
                            </div>

                            <div style={{marginBottom:'10px'}}>
                              <p>max per wallet</p>
                              <input type="text" placeholder="Input item max" className="input-modal" value={max} onChange={(e)=>{setMax(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>blockchain</p>
                              <select placeholder="Input item max" className="input-modal" value={chain} onChange={(e)=>{setChain(e.target.value)}}>
                                <option value="-">NON NFT</option>
                                <option value="eth">ETHEREUM</option>
                                <option value="sol">SOLANA</option>
                              </select>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>contract address</p>
                              <input type="text" placeholder="Input item contract address" className="input-modal" value={contract} onChange={(e)=>{setContract(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>slug</p>
                              <input type="text" placeholder="Input item slug" className="input-modal" value={slug} onChange={(e)=>{setSlug(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>floor price</p>
                              <input type="text" placeholder="Input item price" className="input-modal" value={fp} onChange={(e)=>{setFp(e.target.value)}}></input>
                            </div>
                            <div style={{marginBottom:'10px'}}>
                              <p>Static Price</p>
                              <input type="checkbox" className="input-modal" id="static" name="static" value={staticPrice} defaultChecked={staticPrice} onChange={() => setStaticPrice(!staticPrice)} style={{width:'5%'}}/>
                            </div>

                            <div style={{marginBottom:'10px'}}>
                              <p>Hide</p>
                              <input type="checkbox" className="input-modal" id="hide" name="hide" value={hide} defaultChecked={hide} onChange={() => setHide(!hide)} style={{width:'5%'}}/>
                            </div>
                            <div style={{marginTop:'20px',display:'flex',placeContent:'center'}}>
                              <button style={{padding:'20px'}} className="DiscordButton" onClick={ ()=>{updateMarketplace();close()}}> EDIT ITEM</button>
                            </div>
                          </div>
                        
                        </div>
                      )}
                    </Popup>
                    <Popup
                      trigger={<button className="adminRoundIcon" ><img src={optionIcon} style={{width:'65%'}}></img></button>}
                      position="left top"
                      on="click"
                      contentStyle={{ padding: '5px', border: '1px solid black',color:'black',textAlign:'center' }}
                      arrow={false}
                      nested
                    >
                      <div className="menu">
                        <a href='/updateMarketplace'>
                          <img src={plusIcon} alt="update marketplace link" />
                        </a>

                        <Popup
                            trigger={<div className="menu-item"> <div onClick={()=> {}}> Reset Limit</div></div>}
                            modal
                            nested
                          >
                            
                          {close => (
                            <div className="modal">
                              <button className="close" onClick={close}>
                                &times;
                              </button>
                                <div className="header"> Reset Limit</div>
                              <div className="actions" style={{display:'flex',justifyContent:'center',gap:'20px'}}>
                                <button style={{width:'40%',justifyContent:'center'}} className="DiscordButton" onClick={()=>{resetMarketplace(object.id);close();}}> 
                                  Yes 
                                </button>
                                <button
                                  style={{width:'40%',justifyContent:'center'}} className="DiscordButton"
                                  onClick={() => {
                                    console.log('modal closed ');
                                    close();
                                  }}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          )}
                        </Popup>
                        <hr style={{border:'1px solid #F2B90C'}}></hr>
                        <Popup
                            trigger={<div className="menu-item"> <div onClick={()=> {}}> Delete</div> </div>}
                            modal
                            nested
                          >
                          {close => (
                            <div className="modal">
                              <button className="close" onClick={close}>
                                &times;
                              </button>
                                <div className="header"> Delete</div>
                                <div className="actions" style={{display:'flex',justifyContent:'center',gap:'20px'}}>
                                  <button style={{width:'40%',justifyContent:'center'}} className="DiscordButton" onClick={()=>{deleteMarketplace(object.id);close();}}> 
                                    Yes 
                                  </button>
                                  <button
                                    style={{width:'40%',justifyContent:'center'}} className="DiscordButton"
                                    onClick={() => {
                                      console.log('modal closed ');
                                      close();
                                    }}
                                  >
                                    No
                                  </button>
                                </div>
                            </div>
                          )}
                        </Popup>
                      </div>
                    </Popup>
              </div>
                </div>
                <div className="desc"> {object.description} </div>
                <div style={{display:'flex',gap:'10px',flexWrap:'wrap'}}>
                  <div style={{}}>
                    <b style={{fontSize:'10px'}}>Price </b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                        {object.price ? object.price.toLocaleString() : "N/A"} $KONG

                        </div>
                    </div>
                  </div>

                  <div>
                    <b style={{fontSize:'10px'}}>Supply</b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                        {object.supply ? object.supply.toLocaleString() : "N/A"}
                        </div>
                    </div>
                  </div>

                  <div>
                    <b style={{fontSize:'10px'}}>Max Per Wallet</b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                        {object.max_wallet ? object.max_wallet.toLocaleString() : "N/A"}
                        </div>
                    </div>
                  </div>

                  <div>
                    <b style={{fontSize:'10px'}}>Blockchain</b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                        {object.ticker ? object.ticker.toLocaleString() : "N/A"}
                        </div>
                    </div>
                  </div>
                  <div>
                    <b style={{fontSize:'10px'}}>Contract Address</b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                            {object.contract_address} 
                        </div>
                    </div>
                  </div>

                  <div>
                    <b style={{fontSize:'10px'}}>Slug</b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                            {object.slug} 
                        </div>
                    </div>
                  </div>

                  <div style={{}}>
                    <b style={{fontSize:'10px'}}>Floor Price</b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                        {object.fp ? object.fp.toLocaleString() : "N/A"}
                        </div>
                    </div>
                  </div>

                  <div>
                    <b style={{fontSize:'10px'}}>Hide</b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                            {object.hide == 1 &&
                            'YES'}
                            {object.hide == 0 &&
                            'NO'} 
                        </div>
                    </div>
                  </div>

                  <div>
                    <b style={{fontSize:'10px'}}>Static</b>
                    <div className='priceCard'>
                        <div className='priceTxt'>
                            {object.static_price == 1 &&
                            'YES'}
                            {object.static_price == 0 &&
                            'NO'} 
                        </div>
                    </div>
                  </div>
                </div>
                  
              </div>

              </div>
              </div>
                
                )}

            </div>
          </div>
        </div>
        }
      </div>
      {/* { showModal &&
        <div className='modal-overlay'>
              <div className='modal-title'>
                <a href='https://opensea.io/collection/supremebananas'>
                  <DiscordButton onClick={ ()=>{}} href='' text='BUY ON OPENSEA' blank />
                </a>
              </div>
              
        </div>
        
      } */}
    </>
    );
}

export default Mint;
