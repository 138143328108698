import * as React from "react"
import './Connect.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/Header/Header';
import { useNavigate } from "react-router-dom";
import { useWeb3Context } from '../../context'
//import allow from helper folder 
import allow  from '../../helper/allow.json';

function Connect() {

    let navigate = useNavigate()
    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const [loaded, setLoaded] = React.useState(false)

    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(() => {
        if(web3Provider && allow.includes(address)){
            navigate("/")
        }
    }, [web3Provider]);


    return (
        <>
            <Preloader handleLoad={handleLoad} />
            <Header siteTitle={`Title`} />
            <div className='modal-overlay'>
                    <div className='modal-connect-title'>
                        <div className='modal-connect-txt' >PLEASE CONNECT YOUR WALLET TO CONTINUE</div>
                    </div>
                    <div className='modal-timer'>
                        <button className='ConnectButton' onClick={connect}>
                            Connect Wallet
                        </button>
                    </div>
            </div>
        </>
    );
}

export default Connect;