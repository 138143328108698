import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Web3ContextProvider, useWeb3Context } from './context'
import {ToastContainer,toast} from 'react-toastify'
import Mutate from './pages/Mutate/Mutate';
import MutateTest from './pages/MutateTest/Mutate';
import Airdrop from './pages/Airdrop/Airdrop';
import Stats from './pages/Stats/Stats';
import Connect from "./pages/Connect/Connect";
import UpdateMarketplace from "./pages/UpdateMarketplace/update";
import JungleMarketplace from './pages/Marketplace/Mutate';

//import InventoryTest from './pages/InventoryTest/Inventory';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {


  return (
    <Web3ContextProvider>
      <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MutateTest />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/jnglmarketplace" element={<JungleMarketplace />} />
          <Route path="/airdrop" element={<Airdrop />} />
          <Route path="/connect" element={<Connect />} />
          <Route path ="/updateMarketplace" element={<UpdateMarketplace/>}/>
          {/* <Route path="/test-xyz" element={<MutateTest />} /> */}
        </Routes>
      </Router>
      <ToastContainer
      position="bottom-right"/>
      </div>
      </Web3ContextProvider>
  );
}

export default App;
