import * as React from 'react';
import Logo from "../../assets/icons/sk_logo.svg";
import ConnectButton from '../lib/ConnectWalletButton/ConnectButton';
import TwitterIcon from '../../assets/icons/twitter.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import DiscordIcon from '../../assets/icons/discord.svg';
import navigateTo from '../../utils/navigateTo';
import { motion } from 'framer-motion';

import closeIcon from '../../assets/icons/close.svg';
import burgerIcon from '../../assets/icons/burger.svg';
import './HeaderWallet.scss';

import { useWeb3Context } from '../../context'
import axios from "axios";

const menuBtn = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
};

const base_uri = "https://api.supremekong.com";

const nav = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '130%' },
};
const HeaderWallet = ({ siteTitle, balances }) => {
  const [open, setOpen] = React.useState(false);
  const { web3Provider, connect, disconnect, address } = useWeb3Context()
  const [balance, setBalance] = React.useState(0)
  
  function onNavigate(e) {
    navigateTo(e);
  }

  function handleOpen() {
    setOpen(!open);
  }

  React.useEffect(() => {
    if (web3Provider) {
    }
  }, [web3Provider, balances]);

  React.useEffect(() => {
    if (balances) {
      setBalance(balances)
    } else {
    }
  }, [balances]);

  return (
    <header className='HeaderWallet'>
      <div className='HeaderWallet__content'>
        <div className='HeaderWallet__logo'>
          <div style={{ display: "flex" }}>
            <ul className='HeaderWallet__list'>
              <li>
                <a href='/'>
                  Jungle MARKETPLACE
                </a>
              </li>
              <li>
                <a href='/airdrop'>
                  AIRDROP
                </a>
              </li>
              <li>
                <a href='/stats'>
                  STATS
                </a>
              </li>
              <li>
                <a href='/search'>
                  SEARCH
                </a>
              </li>
            </ul>
          </div>
        </div>
        
        <ul className='HeaderWallet__list'>
        </ul>
        <ul className='HeaderWallet__social' style={{ fontFamily: 'Akira' }}>
          Admin Control Panel
        </ul>
        <motion.button
          animate={open ? 'open' : 'closed'}
          initial={'closed'}
          variants={menuBtn}
          onClick={handleOpen}
          className='HeaderWallet__menu__btn'
        >
          <img src={open ? closeIcon : burgerIcon} alt='' />
        </motion.button>
      </div>
      <motion.nav
        animate={open ? 'open' : 'closed'}
        initial={'closed'}
        variants={nav}
        className='Header__motion__nav'
      >
        <ul className='Header__motion__nav-anchors'>
          <li>
            <a href='/' onClick={() => setOpen(false)}>
              Jungle MARKETPLACE
            </a>
          </li>
          <li>
            <a href='/airdrop' onClick={() => setOpen(false)}>
              AIRDROP
            </a>
          </li>
          <li>
            <a href='/stats' onClick={() => setOpen(false)}>
              STATS
            </a>
          </li>
          <li>
            <a href='/search' onClick={() => setOpen(false)}>
              SEARCH
            </a>
          </li>
        </ul>
        <ul className='Header__social'>
          <a
            className='Header__social__anchor'
            href='https://twitter.com/Supremekongnft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={TwitterIcon} alt='' />
          </a>
          <a
            className='Header__social__anchor'
            href='https://instagram.com/supremekong.nft'
            target='_blank'
            rel='noreferrer'
          >
            <img src={InstagramIcon} alt='' />
          </a>
          <a
            className='Header__social__anchor'
            href='https://discord.com/invite/supremekong'
            target='_blank'
            rel='noreferrer'
          >
            <img src={DiscordIcon} alt='' />
          </a>
        </ul>
      </motion.nav>
    </header>
  );
};

export default HeaderWallet;
