import * as React from 'react';
import Logo from "../../assets/icons/sk_logo.svg";
import DiscordButton from '../lib/DiscordButton/DiscordButton';
import TwitterIcon from '../../assets/icons/twitter.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import navigateTo from '../../utils/navigateTo';
import { motion } from 'framer-motion';

import closeIcon from '../../assets/icons/close.svg';
import burgerIcon from '../../assets/icons/burger.svg';
import './Header.scss';

const menuBtn = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
};

const nav = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '130%' },
};
const Header = ({ siteTitle }) => {
  const [open, setOpen] = React.useState(false);
  function onNavigate(e) {
    navigateTo(e);
  }

  function handleOpen() {
    setOpen(!open);
  }
  return (
    <header className='Header'>
      <div className='HeaderWallet__content'>
        <div className='HeaderWallet__logo' >
          <div style={{display:"flex",}}>
            {/* <a href='https://www.supremekong.com/staking'>
              <img src={Logo} alt='' />
            </a>
            <div className='wallet-balance-desktop'>
              <ConnectButton balances={{"balances":balance}}/>
            </div> */}
            <ul className='HeaderWallet__list'>
             
            </ul>
            <ul className='HeaderWallet__list'>
              
            </ul>
          </div>
        </div>
        
        <ul className='HeaderWallet__list'>
        
        </ul>
        <ul className='HeaderWallet__social' style={{fontFamily:'Akira'}}>
          Admin Control Panel
        </ul>
        <motion.button
          animate={open ? 'open' : 'closed'}
          initial={'closed'}
          variants={menuBtn}
          onClick={handleOpen}
          className='HeaderWallet__menu__btn'
        >
          <img src={open ? closeIcon : burgerIcon} alt='' />
        </motion.button>
      </div>
    </header>
  );
};

export default Header;
