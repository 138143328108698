import * as React from "react"
import {useEffect, useState} from "react"

import './Airdrop.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate, useParams } from "react-router-dom";
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import axios from "axios";
import mutantbanana from '../../assets/images/mutantbanana.png'
import arrow from '../../assets/images/arrow.png'
import ProgressBar from "../../components/Progressbar/Progressbars";
import { fail } from "assert";
import { HmacSHA256, enc } from 'crypto-js';
import Popup from 'reactjs-popup';
import { send } from "process";
const allow = require('../../helper/allow.json')

const mutantbananaAbi = require('../../helper/mutantBanana.json')
const stakingAbi = require('../../helper/stakeAbi.json')
const bananaAbi = require('../../helper/bananaAbi.json')

let interval;

function Mint() {
    const [loadedAddr, setLoadedAddr] = useState(false);
    let navigate = useNavigate()

    let { id } = useParams();

    //const base_uri = "http://localhost:8001";
    const base_uri = "https://api.supremekong.com";
    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
    const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;
    const stakingContract = process.env.REACT_APP_STAKING_CONTRACT;

    const burnCost = 5;

    const [addressField, setAddress] = React.useState("")
    const [search, setSearch] = React.useState("")
    const [loaded, setLoaded] = React.useState(false)
    const [owner, setOwner] = React.useState(false)
    const [potion, setPotion] = React.useState(0)
    const [load, setLoad] = React.useState(false)
    const [count, setCount] = React.useState(0)
    const [level, setlevel] = React.useState(0)
    const [type, setType] = React.useState(2)
    const [eligible, setEligible] = React.useState(true)
    const [maxAmount, setMaxAmount] = React.useState(0)
    const [amount, setAmount] = React.useState(0);
    const [approvedBanana, setApprovedBanana] = React.useState(false)
    const [mintVisible, setMintVisible] = React.useState(true);
    const [countDown, setCountDown] = React.useState(
        new Date("2022-08-31T22:00:00.000-04:00").getTime() - new Date().getTime()
    );
    const [showModal, setShowModal] = React.useState(true);
    const [inventory, setInventory] = React.useState([])
    const [inventorys, setInventorys] = React.useState([])
    const [dropdown, setDropdown] = React.useState("all")
    const [items, setItems] = React.useState(false)
    const [nft, setNft] = React.useState(false)
    const [reedemnft, setReedemNft] = React.useState(false)
    const [day,setDay] = React.useState(0)
    const [hour,setHour] = React.useState(0)
    const [minute,setMinute] = React.useState(0)
    const [second,setSecond] = React.useState(0)
    const [marketId, setMarketId] = React.useState("")
    const [img, setImg] = React.useState("")
    const [title, setTitle] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [sendAll, setSendAll] = React.useState(0)
    
    // State for items to airdrop
    const [ingredients, setIngredients] = React.useState([])
    const [chests, setChests] = React.useState([])
    const [potions, setPotions] = React.useState([])
    const [recipes, setRecipes] = React.useState([])
    const [allItems, setAllItems] = React.useState([])
    const [selectedItem, setSelectedItem] = React.useState("")
    const [itemType, setItemType] = React.useState("all")
    const [isLoading, setIsLoading] = React.useState(false)

    const handleLoad = React.useMemo(() => {
      if (address && address.trim() !== '' && address !== null && address !== undefined) {
        setLoaded(true);
      }
    }, [address]);

    // Check if user is logged in
    useEffect(() => {
      if (address && address.trim() !== '' && address !== null && address !== undefined) {
        setLoadedAddr(true);
      }
    }, [address]);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (!loadedAddr) {
          localStorage.setItem('dest', '/');
          navigate('/connect');
        }
      }, 2000);
  
      return () => {
        clearTimeout(timeoutId);
      };
    }, [loadedAddr]);
  
    useEffect(() => {
      if (loadedAddr && address && !allow.includes(address)) {
        localStorage.setItem('dest', '/');
        navigate('/connect');
      }
    }, [loadedAddr, address]);

    // Fetch ingredients from API and define chests
    useEffect(() => {
      if (loadedAddr) {
        fetchItems();
      }
    }, [loadedAddr]);

    const fetchItems = async () => {
      try {
        setIsLoading(true);
        const now = Math.floor(Date.now() / 1000);
        const body = {
          timestamp: now
        };
        const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body), process.env.REACT_APP_SECRET_KEY));
        
        // Fetch ingredients
        const ingredientsResponse = await axios.get(base_uri + '/getAllIngredients', {
          headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m',
            hash_key: hashkey
          }
        });
        
        // Define chest data directly (IDs 90, 91, 92, 93)
        const chestsData = [
          {
            id: 90,
            title: "Level 10 Chest",
            description: "A bronze chest that contains rewards for reaching level 10",
            image_url: "https://assets.supremekong.com/images/marketplace/chest_level_10.png",
            type: 'chest'
          },
          {
            id: 91,
            title: "Level 15 Chest",
            description: "A silver chest that contains rewards for reaching level 15",
            image_url: "https://assets.supremekong.com/images/marketplace/chest_level_15.png",
            type: 'chest'
          },
          {
            id: 92,
            title: "Level 20 Chest",
            description: "A gold chest that contains rewards for reaching level 20",
            image_url: "https://assets.supremekong.com/images/marketplace/chest_level_20.png",
            type: 'chest'
          },
          {
            id: 93,
            title: "Level 25 Chest",
            description: "A diamond chest that contains rewards for reaching level 25",
            image_url: "https://assets.supremekong.com/images/marketplace/chest_level_25.png",
            type: 'chest'
          }
        ];
        
        setChests(chestsData);
        
        if (ingredientsResponse.data.success) {
          const specialItems = [];
          const potionItems = [];
          const recipeItems = [];
          const ingredientsData = [];
          
          ingredientsResponse.data.data.forEach(item => {
            if (item.id === 1 || item.id === 2 || item.id === 3) {
              // Special items (Whitelist Scroll, Leveling Potion, Power Crystal)
              specialItems.push({
                ...item,
                type: 'special'
              });
            } else if (item.id >= 106 && item.id <= 125) {
              // Leveling Potions (106-125)
              potionItems.push({
                ...item,
                type: 'potion'
              });
            } else if ((item.id >= 201 && item.id <= 225) || item.id === 251) {
              // Recipes (201-225, 251)
              recipeItems.push({
                ...item,
                type: 'recipe'
              });
            } else {
              // Regular ingredients
              ingredientsData.push({
                ...item,
                type: 'ingredient'
              });
            }
          });
          
          setIngredients(ingredientsData);
          setPotions(potionItems);
          setRecipes(recipeItems);
          
          // Combine all item types for the "all" category
          const allItemsData = [
            ...specialItems,
            ...potionItems,
            ...recipeItems,
            ...ingredientsData,
            ...chestsData
          ];
          
          setAllItems(allItemsData);
          
          // Set default selected item if there are any
          if (allItemsData.length > 0) {
            setSelectedItem(allItemsData[0].id.toString());
            setItemType(allItemsData[0].type);
            setImg(allItemsData[0].image_url);
            setTitle(allItemsData[0].title);
            setDescription(allItemsData[0].description);
            setItems(true);
          }
        } else {
          toast.error("Failed to load ingredients");
          
          setAllItems(chestsData);
          if (chestsData.length > 0) {
            setSelectedItem(chestsData[0].id.toString());
            setItemType(chestsData[0].type);
            setImg(chestsData[0].image_url);
            setTitle(chestsData[0].title);
            setDescription(chestsData[0].description);
            setItems(true);
          }
        }
      } catch (err) {
        console.error("Error fetching items:", err);
        toast.error("Error loading items");
        
        const chestsData = [
          {
            id: 90,
            title: "Level 10 Chest",
            description: "A bronze chest that contains rewards for reaching level 10",
            image_url: "https://assets.supremekong.com/images/marketplace/chest_level_10.png",
            type: 'chest'
          },
          {
            id: 91,
            title: "Level 15 Chest",
            description: "A silver chest that contains rewards for reaching level 15",
            image_url: "https://assets.supremekong.com/images/marketplace/chest_level_15.png",
            type: 'chest'
          },
          {
            id: 92,
            title: "Level 20 Chest",
            description: "A gold chest that contains rewards for reaching level 20",
            image_url: "https://assets.supremekong.com/images/marketplace/chest_level_20.png",
            type: 'chest'
          },
          {
            id: 93,
            title: "Level 25 Chest",
            description: "A diamond chest that contains rewards for reaching level 25",
            image_url: "https://assets.supremekong.com/images/marketplace/chest_level_25.png",
            type: 'chest'
          }
        ];
        
        setChests(chestsData);
        setAllItems(chestsData);
        if (chestsData.length > 0) {
          setSelectedItem(chestsData[0].id.toString());
          setItemType(chestsData[0].type);
          setImg(chestsData[0].image_url);
          setTitle(chestsData[0].title);
          setDescription(chestsData[0].description);
          setItems(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    const handleItemChange = (e) => {
      const id = e.target.value;
      setSelectedItem(id);
      
      let selected;
      let itemsToSearch = [];
      
      if (dropdown === "ingredient") {
        itemsToSearch = ingredients;
      } else if (dropdown === "chest") {
        itemsToSearch = chests;
      } else if (dropdown === "potion") {
        itemsToSearch = potions;
      } else if (dropdown === "recipe") {
        itemsToSearch = recipes;
      } else if (dropdown === "special") {
        itemsToSearch = allItems.filter(item => item.type === 'special');
      } else {
        itemsToSearch = allItems;
      }
      
      selected = itemsToSearch.find(item => item.id.toString() === id);
      
      if (selected) {
        setImg(selected.image_url);
        setTitle(selected.title);
        setDescription(selected.description);
        setItemType(selected.type);
        setItems(true);
      }
    };

    const handleDropdownChange = (e) => {
      const value = e.target.value;
      setDropdown(value);
      
      let itemsToShow = [];
      
      if (value === "ingredient") {
        itemsToShow = ingredients;
      } else if (value === "chest") {
        itemsToShow = chests;
      } else if (value === "potion") {
        itemsToShow = potions;
      } else if (value === "recipe") {
        itemsToShow = recipes;
      } else if (value === "special") {
        itemsToShow = allItems.filter(item => item.type === 'special');
      } else {
        itemsToShow = allItems;
      }
      
      if (itemsToShow.length > 0) {
        setSelectedItem(itemsToShow[0].id.toString());
        setItemType(itemsToShow[0].type);
        setImg(itemsToShow[0].image_url);
        setTitle(itemsToShow[0].title);
        setDescription(itemsToShow[0].description);
        setItems(true);
      } else {
        setSelectedItem("");
        setItemType("");
        setImg("");
        setTitle("");
        setDescription("");
        setItems(false);
      }
    };

    const airdropItem = async() => {
      try {
        setIsLoading(true);
        let now = Math.floor(Date.now() / 1000);
        const body = {
          address: addressField,
          itemId: selectedItem,
          itemType: itemType,
          amount: amount,
          timestamp: now,
          callerAddress: address
        };
        const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body), process.env.REACT_APP_SECRET_KEY));
        
        let endpoint;
        if (sendAll === 0) {
          endpoint = '/airdropItem';
        } else {
          endpoint = '/airdropItemToAll';
        }
        
        const resp = await axios.post(base_uri + endpoint, body, {
          headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m',
            hash_key: hashkey
          }
        });
        
        if (resp.data.success) {
          toast.success(`Success Airdrop ${title}`);
        } else {
          toast.error(resp.data.error || `Failed to Airdrop ${title}`);
        }
      } catch (err) {
        console.error("Airdrop error:", err);
        toast.error(`Failed to Airdrop ${title}`);
      } finally {
        setIsLoading(false);
      }
    };

    return (
        <>
        <Preloader handleLoad={handleLoad} />
        <Header siteTitle={`Title`} />
        { loadedAddr &&
        <div className='Inventory__content'>
            <div className='container'>
                <p className="inventory-title">AIRDROP</p>
            </div>
            <div className="inventory-tab">
                <button id="tab-2" className="button-tab-active">ITEMS</button>
            </div>

            {/* Main content - always visible on all devices */}
            <div className="inventory-container">
                <div className="inventory-left">
                    <div className="inventory-contents">
                        <div className="inventory-content">
                          {/* Always visible filter dropdown */}
                          <div className="inventory-select dropdown-container" style={{ display: 'block', margin: '0 auto 20px auto', maxWidth: '100%' }}>
                            <label htmlFor="category" style={{ color: '#F2B90C', display: 'block', marginBottom: '5px', fontSize: '14px' }}>FILTER BY TYPE</label>
                            <select 
                              name="category" 
                              id="category" 
                              className="select-cat" 
                              value={dropdown}
                              onChange={handleDropdownChange}
                              disabled={isLoading}
                              style={{ 
                                width: '100%', 
                                padding: '12px', 
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                color: '#F2B90C',
                                border: '1px solid #F2B90C',
                                borderRadius: '4px',
                                fontSize: '16px',
                                appearance: 'menulist',
                                display: 'block'
                              }}
                            >
                              <option value="all">ALL ITEMS</option>
                              <option value="special">SPECIAL ITEMS</option>
                              <option value="potion">LEVELING POTIONS</option>
                              <option value="recipe">RECIPES</option>
                              <option value="ingredient">INGREDIENTS</option>
                              <option value="chest">CHESTS</option>
                            </select>
                          </div>
                          
                          {/* Item selector dropdown */}
                          <div className="ingredient-selector dropdown-container" style={{ display: 'block', margin: '0 auto 20px auto', maxWidth: '100%' }}>
                            <label htmlFor="item-select" style={{ color: '#F2B90C', display: 'block', marginBottom: '5px', fontSize: '14px' }}>SELECT ITEM</label>
                            <select 
                              id="item-select"
                              className="ingredient-dropdown" 
                              value={selectedItem}
                              onChange={handleItemChange}
                              disabled={isLoading}
                              style={{ 
                                width: '100%', 
                                padding: '12px', 
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                color: '#F2B90C',
                                border: '1px solid #F2B90C',
                                borderRadius: '4px',
                                fontSize: '16px',
                                appearance: 'menulist',
                                display: 'block'
                              }}
                            >
                              {dropdown === "special" && allItems
                                .filter(item => item.type === 'special')
                                .map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                ))
                              }
                              
                              {dropdown === "potion" && allItems
                                .filter(item => item.type === 'potion')
                                .map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                ))
                              }
                              
                              {dropdown === "recipe" && allItems
                                .filter(item => item.type === 'recipe')
                                .map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                ))
                              }
                              
                              {dropdown === "ingredient" && ingredients.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                              
                              {dropdown === "chest" && chests.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                              
                              {dropdown === "all" && allItems.map(item => (
                                <option key={item.id} value={item.id}>
                                  {item.title} ({item.type === 'ingredient' 
                                    ? 'Ingredient' 
                                    : item.type === 'chest' 
                                      ? 'Chest'
                                      : item.type === 'potion'
                                        ? 'Potion'
                                        : item.type === 'recipe'
                                          ? 'Recipe'
                                          : 'Special'})
                                </option>
                              ))}
                            </select>
                          </div>
                          
                          {sendAll === 0 && (
                            <div style={{marginBottom:'20px', width:'100%'}}>
                              <label htmlFor="wallet-address" style={{ color: '#F2B90C', display: 'block', marginBottom: '5px', fontSize: '14px' }}>WALLET ADDRESS</label>
                              <input 
                                id="wallet-address"
                                type="text" 
                                placeholder="Input wallet address" 
                                className="input-modal" 
                                value={addressField} 
                                onChange={(e) => setAddress(e.target.value)}
                                disabled={isLoading}
                                style={{ 
                                  width: '100%', 
                                  padding: '12px', 
                                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                  color: '#F2B90C',
                                  border: '1px solid #F2B90C',
                                  borderRadius: '4px',
                                  fontSize: '16px'
                                }}
                              />
                            </div>
                          )}

                          <div style={{marginBottom:'20px', display: 'flex', alignItems: 'center'}}>
                            <label htmlFor="sendAll" style={{ color: '#F2B90C', marginRight: '10px', fontSize: '14px' }}>SEND TO ALL</label>
                            <input 
                              type="checkbox" 
                              className="input-modal" 
                              id="sendAll" 
                              name="sendAll" 
                              checked={sendAll === 1}
                              onChange={() => setSendAll(sendAll === 0 ? 1 : 0)} 
                              style={{width:'20px', height: '20px'}}
                              disabled={isLoading}
                            />
                          </div>

                          <div style={{marginBottom:'20px', width:'100%'}}>
                            <label htmlFor="amount-input" style={{ color: '#F2B90C', display: 'block', marginBottom: '5px', fontSize: '14px' }}>AMOUNT</label>
                            <input 
                              id="amount-input"
                              style={{
                                textAlign:'center', 
                                fontSize:'20px',
                                width: '100%', 
                                padding: '12px', 
                                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                color: '#F2B90C',
                                border: '1px solid #F2B90C',
                                borderRadius: '4px'
                              }} 
                              type="number" 
                              placeholder="Enter amount" 
                              className="input-modal" 
                              value={amount} 
                              onChange={(e) => setAmount(e.target.value)}
                              disabled={isLoading}
                            />
                          </div>
                          
                          <div style={{marginTop:'20px', display:'flex', width:'100%'}}>
                            <Popup
                              trigger={
                                <button 
                                  style={{
                                    width:'100%', 
                                    justifyContent:'center',
                                    padding: '12px',
                                    backgroundColor: '#F2B90C',
                                    color: 'black',
                                    border: 'none',
                                    borderRadius: '4px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    cursor: isLoading || !selectedItem || (sendAll === 0 && !addressField) || !amount ? 'not-allowed' : 'pointer',
                                    opacity: isLoading || !selectedItem || (sendAll === 0 && !addressField) || !amount ? 0.6 : 1
                                  }} 
                                  className="DiscordButton" 
                                  disabled={isLoading || !selectedItem || (sendAll === 0 && !addressField) || !amount}
                                >
                                  <div>{isLoading ? "PROCESSING..." : "SEND ITEMS"}</div>
                                </button>
                              }
                              modal
                              nested
                              disabled={isLoading}
                            >
                              {close => (
                                <div className="modal">
                                  <button className="close" onClick={close}>
                                    &times;
                                  </button>
                                  <div className="header">
                                    {sendAll === 0 
                                      ? `Send ${title} to ${addressField}` 
                                      : `Send ${title} to All Users`}
                                  </div>
                                  
                                  <div className="content">
                                    <p>Are you sure you want to send {amount} {title}?</p>
                                  </div>
                                  
                                  <div className="actions" style={{display:'flex', justifyContent:'center', gap:'20px'}}>
                                    <button 
                                      style={{width:'40%', justifyContent:'center'}} 
                                      className="DiscordButton" 
                                      onClick={() => {
                                        airdropItem();
                                        close();
                                      }}
                                      disabled={isLoading}
                                    > 
                                      Yes 
                                    </button>
                                    <button
                                      style={{width:'40%', justifyContent:'center'}} 
                                      className="DiscordButton"
                                      onClick={() => close()}
                                      disabled={isLoading}
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                        </div>
                    </div>
                </div>
                <div className="inventory-right">
                    {items && (
                        <div className="inventory-detail">
                            <img src={img} alt={title} />
                            <h4 style={{marginBottom:'20px', marginTop:'15px', color:'white'}}>{title}</h4>
                            <p style={{fontSize:'12px', color:"white", fontFamily:"Lato", marginBottom:"20px"}}>
                              {description}
                            </p>
                            <p style={{fontSize:'14px', color:"white", fontFamily:"Lato", marginBottom:"10px"}}>
                              Type: {
                                itemType === 'ingredient' 
                                  ? 'Ingredient' 
                                  : itemType === 'chest' 
                                    ? 'Chest' 
                                    : itemType === 'potion'
                                      ? 'Leveling Potion'
                                      : itemType === 'recipe'
                                        ? 'Recipe'
                                        : 'Special Item'
                              }
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
        }
        </>
    );
}

export default Mint;
