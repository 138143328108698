import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import { useNavigate } from "react-router-dom";
import { useWeb3Context } from '../../context';
import Header from '../../components/HeaderWallet/HeaderWallet';
import './Search.scss';

const allow = require('../../helper/allow.json');

// Simple ERC20 ABI for token balance and decimals
const erc20Abi = [
  {
    constant: true,
    inputs: [{ name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    type: "function",
  },
];

// Simple staking ABI for JNGL token
const stakingAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_stakeholder",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_poolId",
        type: "uint256",
      },
    ],
    name: "getStaked",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  }
];

// Simple NFT ABI for balanceOf
const nftAbi = [
  {
    constant: true,
    inputs: [{ name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    type: "function",
  }
];

const SearchPage = () => {
  const navigate = useNavigate();
  const { address: adminAddress } = useWeb3Context();
  
  // State variables
  const [searchAddress, setSearchAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [resultData, setResultData] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');

  // Contract addresses
  const contracts = {
    jnglToken: "0x4C45bbEc2fF7810ef4a77ad7BD4757C446Fe4155",
    jnglStaking: "0x579D27a47A270601b041F8B36e7F5CBC37508B40",
    supremeKong: process.env.REACT_APP_SUPREME_KONG_ADDRESS || "0x12787526c03d626AAc88E6EDc4d0Fb930d86C631",
    supremeBanana: process.env.REACT_APP_BANANA_ADDRESS || "0xF59aFe732B2793d541780fd02945228db1fAd8ed",
    mutantBanana: process.env.REACT_APP_MUTANT_BANANA_CONTRACT || "0x8ACe1Ec951Bc29b98440d1bbCa64E6010c9E9E17",
    jnglNft: process.env.REACT_APP_JNGL_NFT_ADDRESS || "0x54fec6309b53a31e65593f196b4c58f7a704a361"
  };

  // Base URL for API requests
  const base_uri = "https://api.supremekong.com";

  // Helper function to format token amounts with only 2 decimal places
  const formatTokenAmount = (amount, decimals = 18, displayDecimals = 2) => {
    try {
      // Convert to ethers BigNumber if it's not already
      const bigNumberAmount = ethers.BigNumber.isBigNumber(amount) 
        ? amount 
        : ethers.BigNumber.from(amount.toString());
      
      // Format with ethers.js
      const formattedAmount = ethers.utils.formatUnits(bigNumberAmount, decimals);
      
      // Parse to float and limit to displayDecimals
      const numAmount = parseFloat(formattedAmount);
      
      // Format to fixed number of decimal places
      return numAmount.toFixed(displayDecimals);
    } catch (error) {
      console.error("Error formatting token amount:", error);
      // Fallback to simple string conversion
      return parseFloat(amount.toString() || "0").toFixed(displayDecimals);
    }
  };

  // Admin check
  useEffect(() => {
    if (adminAddress && !allow.includes(adminAddress)) {
      localStorage.setItem('dest', '/');
      navigate('/connect');
    }
  }, [adminAddress, navigate]);

  // Search for wallet data
  const searchWallet = async () => {
    if (!ethers.utils.isAddress(searchAddress)) {
      setError('Please enter a valid Ethereum address');
      return;
    }

    setLoading(true);
    setError('');
    setResultData(null);

    try {
      // Initialize provider
      const provider = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_MAINNET_RPC_URL || "https://mainnet.infura.io/v3/3ddebb74c04041729b4be1fc6d6f95ff"
      );
      
      // Create contract instances
      const jnglTokenContract = new ethers.Contract(contracts.jnglToken, erc20Abi, provider);
      const jnglStakingContract = new ethers.Contract(contracts.jnglStaking, stakingAbi, provider);
      const skContract = new ethers.Contract(contracts.supremeKong, nftAbi, provider);
      const sbContract = new ethers.Contract(contracts.supremeBanana, nftAbi, provider);
      const mbContract = new ethers.Contract(contracts.mutantBanana, nftAbi, provider);
      const jnglNftContract = new ethers.Contract(contracts.jnglNft, nftAbi, provider);

      // Create a timeout promise to prevent hanging
      const timeout = ms => new Promise((_, reject) => 
        setTimeout(() => reject(new Error(`Request timed out after ${ms}ms`)), ms)
      );

      // Fetch token balances and staking info
      const fetchBalanceWithTimeout = async (contract, method, args = [], timeoutMs = 5000) => {
        try {
          const result = await Promise.race([
            contract[method](...args),
            timeout(timeoutMs)
          ]);
          return result;
        } catch (error) {
          console.warn(`Warning: Failed to call ${method}:`, error.message);
          return null;
        }
      };

      // 1. Fetch $JNGL token data
      const jnglDecimals = await fetchBalanceWithTimeout(jnglTokenContract, 'decimals') || 18;
      const jnglBalance = await fetchBalanceWithTimeout(jnglTokenContract, 'balanceOf', [searchAddress]);
      const jnglStaked = await fetchBalanceWithTimeout(jnglStakingContract, 'getStaked', [searchAddress, 0]);

      // 2. Fetch NFT balances (unstaked)
      const skBalance = await fetchBalanceWithTimeout(skContract, 'balanceOf', [searchAddress]);
      const sbBalance = await fetchBalanceWithTimeout(sbContract, 'balanceOf', [searchAddress]);
      const mbBalance = await fetchBalanceWithTimeout(mbContract, 'balanceOf', [searchAddress]);
      const jnglNftBalance = await fetchBalanceWithTimeout(jnglNftContract, 'balanceOf', [searchAddress]);

      // 3. Fetch database data
      const headers = {
        api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m',
      };

      // Get wallet info from database
      const walletResponse = await axios.get(`${base_uri}/search-wallet?address=${searchAddress}`, { headers })
        .catch(error => {
          console.log("Error fetching wallet data, using mock data:", error);
          return { 
            data: { 
              wallet: { created: 'N/A', lastActive: 'N/A' },
              ingredients: []
            } 
          };
        });
      
      // Get NFT staking data from database
      const stakedNftsResponse = await axios.get(`${base_uri}/staked-nfts-by-address?address=${searchAddress}`, { headers })
        .catch(error => {
          console.log("Error fetching staked NFTs, using empty data:", error);
          return { 
            data: { 
              stakedNfts: [] // Change to expect an array of NFTs instead of pre-calculated totals
            } 
          };
        });
      
      // Process staked NFTs to get counts by collection
      let skStaked = 0, sbStaked = 0, mbStaked = 0, jnglNftStaked = 0;

      // Check if we have a valid response with the expected structure
      if (stakedNftsResponse.data && stakedNftsResponse.data.stakedNfts) {
        // Count staked NFTs by type
        stakedNftsResponse.data.stakedNfts.forEach(nft => {
          if (!nft || !nft.contractAddress) return;
          
          const contractAddress = nft.contractAddress.toLowerCase();
          
          if (contractAddress === contracts.supremeKong.toLowerCase()) {
            skStaked++;
          } else if (contractAddress === contracts.supremeBanana.toLowerCase()) {
            sbStaked++;
          } else if (contractAddress === contracts.mutantBanana.toLowerCase()) {
            mbStaked++;
          } else if (contractAddress === contracts.jnglNft.toLowerCase()) {
            jnglNftStaked++;
          }
        });
      } else if (stakedNftsResponse.data) {
        // Fallback if API already returns totals but structure is different
        skStaked = stakedNftsResponse.data.skStaked || 0;
        sbStaked = stakedNftsResponse.data.sbStaked || 0;
        mbStaked = stakedNftsResponse.data.mbStaked || 0;
        jnglNftStaked = stakedNftsResponse.data.jnglStaked || 0;
      }
      
      // Get user's ingredients
      let ingredients = walletResponse.data.ingredients || [];
      
      // If no ingredients were found in the wallet response, try to fetch them from the inventory endpoint
      if (ingredients.length === 0) {
        try {
          const inventoryResponse = await axios.get(`${base_uri}/admin-get-inventory?address=${searchAddress}`, { headers });
          if (inventoryResponse.data && inventoryResponse.data.result) {
            ingredients = inventoryResponse.data.result.map(item => ({
              id: item.marketplace_id,
              title: item.title || 'Unknown Ingredient',
              image_url: item.image_url || '',
              amount: item.amount || 0
            }));
          }
        } catch (error) {
          console.log("Error fetching inventory:", error);
        }
      }

      // Process all the data
      const data = {
        address: searchAddress,
        jnglToken: {
          // Format token values with only 2 decimal places for display
          balance: jnglBalance ? formatTokenAmount(jnglBalance, jnglDecimals, 2) : '0.00',
          staked: jnglStaked ? formatTokenAmount(jnglStaked, jnglDecimals, 2) : '0.00',
          // Keep raw values for calculations
          rawBalance: jnglBalance || ethers.BigNumber.from('0'),
          rawStaked: jnglStaked || ethers.BigNumber.from('0')
        },
        nfts: {
          supremeKong: {
            balance: skBalance ? skBalance.toNumber() : 0,
            staked: skStaked
          },
          supremeBanana: {
            balance: sbBalance ? sbBalance.toNumber() : 0,
            staked: sbStaked
          },
          mutantBanana: {
            balance: mbBalance ? mbBalance.toNumber() : 0,
            staked: mbStaked
          },
          jnglNft: {
            balance: jnglNftBalance ? jnglNftBalance.toNumber() : 0,
            staked: jnglNftStaked
          }
        },
        ingredients: ingredients,
        walletInfo: walletResponse.data.wallet || { created: 'N/A', lastActive: 'N/A' }
      };

      setResultData(data);
    } catch (error) {
      console.error("Error searching wallet:", error);
      setError(`Error searching wallet: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Calculate total JNGL tokens (wallet + staked) with proper formatting
  const calculateTotalJnglTokens = () => {
    if (!resultData) return '0.00';
    
    try {
      if (resultData.jnglToken.rawBalance && resultData.jnglToken.rawStaked) {
        // Use BigNumber math for accurate calculations with large numbers
        const total = resultData.jnglToken.rawBalance.add(resultData.jnglToken.rawStaked);
        return formatTokenAmount(total, 18, 2);
      } else {
        // Fallback to parsing displayed values
        const balance = parseFloat(resultData.jnglToken.balance) || 0;
        const staked = parseFloat(resultData.jnglToken.staked) || 0;
        return (balance + staked).toFixed(2);
      }
    } catch (error) {
      console.error("Error calculating total JNGL tokens:", error);
      return '0.00';
    }
  };

  // Handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchWallet();
    }
  };

  return (
    <>
      <Header siteTitle={`Search`} />
      <div className="search-page-container">
        <div className="search-page">
          <h1 className="search-title">WALLET SEARCH</h1>
          
          <div className="search-input-container">
            <input
              type="text"
              className="search-input"
              placeholder="Enter wallet address (0x...)"
              value={searchAddress}
              onChange={(e) => setSearchAddress(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button 
              className="search-button" 
              onClick={searchWallet}
              disabled={loading}
            >
              {loading ? 'SEARCHING...' : 'SEARCH'}
            </button>
          </div>
          
          {error && <div className="search-error">{error}</div>}
          
          {resultData && (
            <div className="search-results">
              <div className="search-address">
                <h2>ADDRESS</h2>
                <p>{resultData.address}</p>
                <div className="wallet-info">
                  <span>Created: {resultData.walletInfo.created}</span>
                  <span>Last Active: {resultData.walletInfo.lastActive}</span>
                </div>
              </div>
              
              <div className="tab-navigation">
                <button 
                  className={activeTab === 'overview' ? 'tab-active' : ''} 
                  onClick={() => setActiveTab('overview')}
                >
                  OVERVIEW
                </button>
                <button 
                  className={activeTab === 'nfts' ? 'tab-active' : ''} 
                  onClick={() => setActiveTab('nfts')}
                >
                  NFTs
                </button>
                <button 
                  className={activeTab === 'ingredients' ? 'tab-active' : ''} 
                  onClick={() => setActiveTab('ingredients')}
                >
                  INGREDIENTS
                </button>
                <button 
                  className={activeTab === 'tokens' ? 'tab-active' : ''} 
                  onClick={() => setActiveTab('tokens')}
                >
                  TOKENS
                </button>
              </div>
              
              {activeTab === 'overview' && (
                <div className="overview-tab">
                  <div className="overview-grid">
                    <div className="overview-item">
                      <h3>$JNGL</h3>
                      <div className="overview-values">
                        <div>Balance: {resultData.jnglToken.balance}</div>
                        <div>Staked: {resultData.jnglToken.staked}</div>
                      </div>
                    </div>
                    
                    <div className="overview-item">
                      <h3>NFTs</h3>
                      <div className="overview-values">
                        <div>Supreme Kong: {resultData.nfts.supremeKong.balance + resultData.nfts.supremeKong.staked}</div>
                        <div>Supreme Banana: {resultData.nfts.supremeBanana.balance + resultData.nfts.supremeBanana.staked}</div>
                        <div>Mutant Banana: {resultData.nfts.mutantBanana.balance + resultData.nfts.mutantBanana.staked}</div>
                        <div>JNGL NFT: {resultData.nfts.jnglNft.balance + resultData.nfts.jnglNft.staked}</div>
                      </div>
                    </div>
                    
                    <div className="overview-item">
                      <h3>INGREDIENTS</h3>
                      <div className="overview-values">
                        <div>Unique Types: {resultData.ingredients.length}</div>
                        <div>Total Quantity: {resultData.ingredients.reduce((sum, ing) => sum + (ing.amount || 0), 0)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              {activeTab === 'nfts' && (
                <div className="nfts-tab">
                  <div className="nft-grid">
                    <div className="nft-collection">
                      <h3>SUPREME KONG</h3>
                      <div className="nft-stats">
                        <div className="nft-stat">
                          <span>Wallet:</span>
                          <span>{resultData.nfts.supremeKong.balance}</span>
                        </div>
                        <div className="nft-stat">
                          <span>Staked:</span>
                          <span>{resultData.nfts.supremeKong.staked}</span>
                        </div>
                        <div className="nft-stat total">
                          <span>Total:</span>
                          <span>{resultData.nfts.supremeKong.balance + resultData.nfts.supremeKong.staked}</span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="nft-collection">
                      <h3>SUPREME BANANA</h3>
                      <div className="nft-stats">
                        <div className="nft-stat">
                          <span>Wallet:</span>
                          <span>{resultData.nfts.supremeBanana.balance}</span>
                        </div>
                        <div className="nft-stat">
                          <span>Staked:</span>
                          <span>{resultData.nfts.supremeBanana.staked}</span>
                        </div>
                        <div className="nft-stat total">
                          <span>Total:</span>
                          <span>{resultData.nfts.supremeBanana.balance + resultData.nfts.supremeBanana.staked}</span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="nft-collection">
                      <h3>MUTANT BANANA</h3>
                      <div className="nft-stats">
                        <div className="nft-stat">
                          <span>Wallet:</span>
                          <span>{resultData.nfts.mutantBanana.balance}</span>
                        </div>
                        <div className="nft-stat">
                          <span>Staked:</span>
                          <span>{resultData.nfts.mutantBanana.staked}</span>
                        </div>
                        <div className="nft-stat total">
                          <span>Total:</span>
                          <span>{resultData.nfts.mutantBanana.balance + resultData.nfts.mutantBanana.staked}</span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="nft-collection">
                      <h3>JNGL NFT</h3>
                      <div className="nft-stats">
                        <div className="nft-stat">
                          <span>Wallet:</span>
                          <span>{resultData.nfts.jnglNft.balance}</span>
                        </div>
                        <div className="nft-stat">
                          <span>Staked:</span>
                          <span>{resultData.nfts.jnglNft.staked}</span>
                        </div>
                        <div className="nft-stat total">
                          <span>Total:</span>
                          <span>{resultData.nfts.jnglNft.balance + resultData.nfts.jnglNft.staked}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              {activeTab === 'ingredients' && (
                <div className="ingredients-tab">
                  {resultData.ingredients.length > 0 ? (
                    <div className="ingredients-grid">
                      {resultData.ingredients.map((ingredient, index) => (
                        <div className="ingredient-item" key={index}>
                          <div className="ingredient-img">
                            <img src={ingredient.image_url} alt={ingredient.title} />
                          </div>
                          <div className="ingredient-info">
                            <h3>{ingredient.title}</h3>
                            <p>Amount: {ingredient.amount}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="no-ingredients">No ingredients found for this wallet</div>
                  )}
                </div>
              )}
              
              {activeTab === 'tokens' && (
                <div className="tokens-tab">
                  <div className="token-grid">
                    <div className="token-item">
                      <h3>$JNGL TOKEN</h3>
                      <div className="token-stats">
                        <div className="token-stat">
                          <span>Wallet Balance:</span>
                          <span>{resultData.jnglToken.balance}</span>
                        </div>
                        <div className="token-stat">
                          <span>Staked Amount:</span>
                          <span>{resultData.jnglToken.staked}</span>
                        </div>
                        <div className="token-stat total">
                          <span>Total:</span>
                          <span>{calculateTotalJnglTokens()}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchPage;
