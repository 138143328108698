import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Stats.scss';
import { useNavigate } from "react-router-dom";
import { useWeb3Context } from '../../context';
import Header from '../../components/HeaderWallet/HeaderWallet';
const allow = require('../../helper/allow.json');

const StatsPage = () => {
  let navigate = useNavigate();
  const { web3Provider, connect, disconnect, address } = useWeb3Context();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalWallets: 0,
    totalStakedWallets: 0,
    skStaked: 0,
    jnglNftStaked: 0,
    supremeBananasStaked: 0,
    mutantBananasStaked: 0,
    totalIngredients: 0,
    uniqueIngredients: 0,
    ingredients: []
  });

  const base_uri = "https://api.supremekong.com"; 
  //const base_uri = "http://localhost:8001";

  useEffect(() => {
    // Redirect if not an admin
    if (address && !allow.includes(address)) {
      localStorage.setItem('dest', '/');
      navigate('/connect');
    }
  }, [address]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const headers = {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m',
        };

        const response = await axios.get(`${base_uri}/getEnhancedStats`, { headers });
        console.log("API Response:", response.data);

        setStats({
          totalWallets: response.data.total_wallets || 0,
          totalStakedWallets: response.data.total_staked_wallets || 0, // Fixed: using total_staked_wallets instead of staked_wallets
          skStaked: response.data.sk_staked || 0,
          jnglNftStaked: response.data.jngl_nft_staked || 0,
          supremeBananasStaked: response.data.supreme_bananas_staked || 0,
          mutantBananasStaked: response.data.mutant_bananas_staked || 0,
          totalIngredients: response.data.total_ingredients || 0,
          uniqueIngredients: response.data.unique_ingredients || 0,
          ingredients: response.data.ingredients || []
        });
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header siteTitle={`Stats`} />
      <div className="stats-page-container">
        <div className="stats-page">
          <h1 className="stats-section-title">STATISTICS</h1>
          
          <div className="stats-grid">
            <div className="stat-item">
              <h2>TOTAL WALLETS</h2>
              <p>{stats.totalWallets}</p>
            </div>
            
            <div className="stat-item">
              <h2>TOTAL STAKED WALLETS</h2>
              <p>{stats.totalStakedWallets}</p>
            </div>
          </div>
          
          <h1 className="stats-section-title">STAKING STATISTICS</h1>
          
          <div className="stats-grid">
            <div className="stat-item">
              <h2>SK STAKED</h2>
              <p>{stats.skStaked}</p>
            </div>
            
            <div className="stat-item">
              <h2>JNGL NFT STAKED</h2>
              <p>{stats.jnglNftStaked}</p>
            </div>
            
            <div className="stat-item">
              <h2>SUPREME BANANAS STAKED</h2>
              <p>{stats.supremeBananasStaked}</p>
            </div>
            
            <div className="stat-item">
              <h2>MUTANT BANANAS STAKED</h2>
              <p>{stats.mutantBananasStaked}</p>
            </div>
          </div>
          
          <h1 className="stats-section-title">INGREDIENT STATISTICS</h1>
          
          <div className="stats-grid">
            <div className="stat-item span-2">
              <h2>TOTAL INGREDIENTS IN SYSTEM</h2>
              <p>{stats.totalIngredients}</p>
            </div>
            
            <div className="stat-item span-2">
              <h2>UNIQUE INGREDIENT TYPES</h2>
              <p>{stats.uniqueIngredients}</p>
            </div>
          </div>
          
          <h1 className="stats-section-title">INGREDIENT BREAKDOWN</h1>
          
          <div className="ingredients-grid">
            {stats.ingredients.map(ingredient => (
              <div className="ingredient-item" key={ingredient.id}>
                <div className="ingredient-img">
                  <img src={ingredient.image_url} alt={ingredient.title} />
                </div>
                <div className="ingredient-info">
                  <h3>{ingredient.title}</h3>
                  <p>Total: {parseInt(ingredient.total_amount || 0)}</p>
                  <p>Wallets: {parseInt(ingredient.wallets_with_item || 0)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StatsPage;
