import * as React from "react"
import './Mutate.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate } from "react-router-dom";
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import mutantbanana from '../../assets/images/mutantbanana.png'

const mutantbananaAbi = require('../../helper/mutantBanana.json')
const bananaAbi = require('../../helper/bananaAbi.json')

function Mint() {
    let navigate = useNavigate()


    const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
    const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;

    const burnCost = 5;


    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const [loaded, setLoaded] = React.useState(false)

    const [bananaOwned, setBananaOwned] = React.useState(0)

    const [maxAmount, setMaxAmount] = React.useState(0)

    const [amount, setAmount] = React.useState(0);

    const [approvedBanana, setApprovedBanana] = React.useState(false)

    const [mintVisible, setMintVisible] = React.useState(false);

    const countDownDate = new Date("2022-12-01T13:00:00.000-05:00").getTime();
    const [showModal, setShowModal] = React.useState(true);

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    const [bananaNft, setbananaNft] = React.useState([])

    const [day,setDay] = React.useState(0)
    const [hour,setHour] = React.useState(0)
    const [minute,setMinute] = React.useState(0)
    const [second,setSecond] = React.useState(0)

    React.useEffect(() => {
        const interval = setInterval(() => {
          setCountDown(countDownDate - new Date().getTime());
        }, 1000);
    
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    
        setDay(days)
        setHour(hours)
        setMinute(minutes)
        setSecond(seconds)
        //reduxStore.dispatch(mintEnabled(true))
        if(days + hours + minutes + seconds <= 0){
          setShowModal(false);
          setMintVisible(true);
        }
        return () => clearInterval(interval);
      }, [countDown]);

    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(async() => {
        //Runs only on the first render
        if(web3Provider){
            loadBananaNft();
        }else{
        }
    }, [web3Provider])
    

    const loadBananaNft = async() => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(bananaContract, bananaAbi, signer);
        const totalSupply = await contract.balanceOf(address)
        const totalNft = totalSupply.toNumber()
        setBananaOwned(totalNft)
        if(totalNft > 0){
            const maxMutate = Math.floor(totalNft/ burnCost);
            console.log(maxMutate)
            setMaxAmount(maxMutate)
        }


        const approval = await contract.isApprovedForAll(address,mutantBananaContract)
        setApprovedBanana(approval)

        let tempArr = Array()
        for(let i = 0; i < totalNft; i++){
            const nft = await contract.tokenOfOwnerByIndex(address,i);
            tempArr.push(nft.toNumber())
        }
        setbananaNft(tempArr);

    }

    const increment = () => {
        if(amount < maxAmount){
            setAmount(amount+1);
        }
    }

    const decrement = () => {
        if(amount > 0){
            setAmount(amount-1);
        }
    }

    const mutate = async() => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(mutantBananaContract, mutantbananaAbi, signer);
        try{
            const tx = await contract.mutate(address)

            let receipt = await toast.promise(
            tx.wait(),
            {
                pending: 'Mutation in Progress',
                success: 'Mutation Success',
                error: 'Mutation Failed'
            })

            loadBananaNft()
            toast.success("Mutation Success")
        }catch(err){
            console.log(err)
            toast.success("Mutation Failed")
        }
    }

    const batchMutate = async() => {
        
        if(amount > 0){
            const signer = web3Provider?.getSigner();
            const contract = new ethers.Contract(mutantBananaContract, mutantbananaAbi, signer);


            let tempArr = bananaNft
            let newArr = Array()
            for(let i = 0; i < (amount*5); i++){
                newArr.push(tempArr[i])
            }
            let tempArray = bananaNft;
            console.log(tempArray)
            try{
                const tx = await contract.batchMutate(address,newArr)

                let receipt = await toast.promise(
                tx.wait(),
                {
                    pending: 'Mutation in Progress',
                    success: 'Mutation Success',
                    error: 'Mutation Failed'
                })

                loadBananaNft()
                setAmount(0)
                //toast.success("Mutation Success")
            }catch(err){
                console.log(err)
                toast.error("Mutation Failed")
            }
        }else{
            toast.error("Amount must be bigger than 0")
        }
    }

    const bananaApproveAll = async() => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(bananaContract, bananaAbi, signer);
        try{
            const tx = await contract.setApprovalForAll(mutantBananaContract, true)
            let receipt = await toast.promise(
                tx.wait(),
                {
                    pending: 'Validation in Progress',
                    success: 'Validation Success',
                    error: 'Validation Failed'
                })
            const approval = await contract.isApprovedForAll(address,mutantBananaContract)

            setApprovedBanana(approval)
        }catch(err){
            console.log(err)
            toast.success("Approval Failed")
        }
    }


    return (
        <>
        <Preloader handleLoad={handleLoad} />
        <Header siteTitle={`Title`} />
        <div className='Mint__content'>
        {/* <motion.h1 
        {...paragraphAnimation}
        className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
        <div className='flex-container'>
        { mintVisible &&
        <>
          <div className='flex-item-right' style={{color:'white'}}>
    
            <div className='address' style={{marginBottom:"20px",color:'#f2b90c'}}>
                <p style={{fontSize:"18px",fontWeight:"800",color:'#f2b90c'}}>Supreme Banana Owned</p>
            </div>
            <div className='address' style={{marginBottom:"20px",color:'#f2b90c'}}>
                <p style={{fontSize:"32px",fontWeight:"800",color:'#f2b90c'}}>{bananaOwned}</p>
            </div>
            <div className='address' style={{marginBottom:"20px",color:'#f2b90c'}}>
                <p style={{fontSize:"18px",fontWeight:"800",color:'#f2b90c'}}>Kong Owned</p>
            </div>
            <div className='address' style={{marginBottom:"20px",color:'#f2b90c'}}>
                <p style={{fontSize:"32px",fontWeight:"800",color:'#f2b90c'}}>{bananaOwned}</p>
            </div>
            <div className='address' style={{marginBottom:"20px",color:'#f2b90c'}}>
                <p style={{fontSize:"18px",fontWeight:"800",color:'#f2b90c'}}>Mutant Banana Owned</p>
            </div>

            <div className='address' style={{marginBottom:"20px",color:'#f2b90c'}}>
                <p style={{fontSize:"32px",fontWeight:"800",color:'#f2b90c'}}>{bananaOwned}</p>
            </div>
            
            <div className='address' style={{marginBottom:"20px",color:'#f2b90c'}}>
              <p style={{fontSize:"64px",fontWeight:"800"}}></p>
            </div>

            <div className='address'>
              <p style={{fontSize:"18px",fontWeight:"800",color:'#f2b90c'}}>Amount</p>
            </div>

            <div className='amountBtn'>
              <div className='decrement' onClick={decrement}>
              </div>

              <div className='amountTxt'>
                {amount}
              </div>

              <div className='increment' onClick={increment}>
              </div>
            </div>

            { approvedBanana &&
                <div className='mintBtn' onClick={batchMutate}>
                    MUTATE
                </div>
            }

            { !approvedBanana &&
                <div className='mintBtn' onClick={bananaApproveAll}>
                    VALIDATE
                </div>
            }   

            { amount > 0 &&
            <>
                <div className='address' style={{marginBottom:"20px",color:'#f2b90c',marginTop:20}}>
                    <p style={{fontSize:"18px",fontWeight:"800",color:'#f2b90c'}}>Supreme Banana Burned</p>
                </div>

                <div className='address' style={{marginBottom:"20px",color:'#f2b90c'}}>
                    <p style={{fontSize:"32px",fontWeight:"800",color:'#f2b90c'}}>{amount * burnCost}</p>
                </div>
            </>
            }
          </div>
          </>
          }
          { showModal &&
            <div className='modal-overlay'>
                    <div className='modal-title'>
                    <div className='modal-title-txt' >Countdown To Mint</div>
                    </div>
                    <div className='modal-timer'>
                    <div className='modal-timer-txt'>{day}:{hour}:{minute}.{second}</div>
                    </div>
            </div>
          }
        </div>
      </div>
        </>
    );
}

export default Mint;
