function navigateTo(e) {
    if (e.preventDefault) {
        e.preventDefault()
    }

    const { href } = e.currentTarget
    requestAnimationFrame(() => {
        window.scroll({
            top:
                (document.querySelector(
                    href.slice(href.lastIndexOf("/") + 1),
                )).offsetTop - 80,
            behavior: "smooth",
        })
    })
}

export default navigateTo