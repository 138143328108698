import * as React from "react"
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate } from "react-router-dom";
import { ethers } from 'ethers'
import axios from "axios";
import { toast } from 'react-toastify';
import 'reactjs-popup/dist/index.css';
import './update.scss'
const allow = require('../../helper/allow.json')

const Update = () => {
    const { web3Provider, connect, disconnect, address } = useWeb3Context()
    let navigate = useNavigate()

    const [loadedAddr, setLoadedAddr] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false)
    const [nfts, setNfts] = React.useState([])
    const [activeCollections, setActiveCollections] = React.useState([])
    const [activeNfts, setActiveNfts] = React.useState([])
    const [selectedNfts, setSelectedNfts] = React.useState([])
    const [openCollections, setOpenCollections] = React.useState({});

    const base_uri = "https://api.supremekong.com";

    const ERC721_ABI = [
        "function balanceOf(address owner) view returns (uint256)",
        "function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256 tokenId)",
        "function tokenURI(uint256 tokenId) view returns (string)",
      ];


    React.useEffect(() => {
        if (address) {
            setLoaded(true)
        }
    }, [address])

    React.useEffect(() => {
        if(!allow.includes(address)){
            if (loaded) {
            navigate('/connect')
            }
        }
    }, [address, loaded])



    React.useEffect(() => {
            const fetchData = async () => {
                await loadCollections()
            }
            fetchData()

    }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            await loadNfts();
            await loadMarketplace()
        }
        if (activeCollections.length > 0) {
            fetchData()
        }
    }, [activeCollections])




    const loadCollections = async () => {
        try {
            const res = await axios.get(base_uri + '/fetchMarketplaceCollections', {
              headers: {
                api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m'
              }
            });
            setActiveCollections(res.data);
          } catch (error) {
            toast.error('Error fetching marketplace collections');
          }
    }

    const loadNfts = async () => {
        const privateKey = process.env.REACT_APP_MARKETPLACE_WALLET_KEY;
        if (!privateKey) {
            toast.error("Private key not found in environment variables!");
            return;
        }

        const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/3ddebb74c04041729b4be1fc6d6f95ff'); 
        const wallet = new ethers.Wallet(privateKey, provider);

        try {
            const allNfts = [];

            for (const collection of activeCollections) {
                if (collection.address) {
                    console.log(`Processing collection: ${collection.title}`);
                    const contract = new ethers.Contract(collection.address, ERC721_ABI, wallet);
                    const balance = await contract.balanceOf(wallet.address);
                    console.log(`Processing collection: ${collection.title}`);

                    const nftPromises = [];
                    for (let i = 0; i < balance; i++) {
                        nftPromises.push(fetchNftData(contract, wallet.address, i, collection));
                    }

                    const nftResults = await Promise.all(nftPromises);
                    allNfts.push(...nftResults);
                }
            }

            setNfts(allNfts);
            toast.success("NFTs loaded from wallet.");
        } catch (error) {
            console.error('Error fetching NFTs:', error);
            toast.error('Error fetching NFTs.');
        }
    };
    
    const fetchNftData = async (contract, walletAddress, index, collection) => {
        try {
            const tokenId = await contract.tokenOfOwnerByIndex(walletAddress, index);
            const tokenURI = await contract.tokenURI(tokenId);
            let metadata = {};
    
            if (tokenURI.startsWith("ipfs://")) {
                const ipfsHash = tokenURI.split("ipfs://")[1];
                const metadataResponse = await axios.get(`https://ipfs.io/ipfs/${ipfsHash}`);
                metadata = metadataResponse.data;
            } else {
                const metadataResponse = await axios.get(tokenURI, {
                    transformResponse: [function (data) {
                        try {
                            return JSON.parse(data);
                        } catch (e) {
                            return data;
                        }
                    }]
                });
                metadata = metadataResponse.data;
            }

            let imageUrl = metadata.image || 'No image available';
            if (imageUrl.startsWith("ipfs://")) {
                const ipfsImageHash = imageUrl.split("ipfs://")[1];
                imageUrl = `https://ipfs.io/ipfs/${ipfsImageHash}`;
            }

    
            return {
                tokenId: tokenId.toString(),
                imageUrl: imageUrl,
                contractAddress: collection.address,
                collectionName: collection.title
            };
        } catch (error) {
            console.error('Error fetching NFT data:', error);
            return {
                tokenId: '',
                imageUrl: 'No image available',
                contractAddress: collection.address,
                collectionName: collection.title
            };
        }
    };

    const loadMarketplace = async () => {
        try {
            const res = await axios.get(base_uri + '/getMarketplaceNfts', {
                headers: {
                    api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m'
                }
            });
            setActiveNfts(res.data);
        } catch (error) {
            toast.error('Error loading marketplace NFTs:');
        }
    }

    const isActive = (contractAddress, tokenId) => {
        return activeNfts.some(nft => nft.contractAddress === contractAddress && nft.tokenId === tokenId);
    };

    const addNfts = async () => {
        const nftsToAdd = selectedNfts.filter(selectedNft =>
            !activeNfts.some(activeNft =>
                activeNft.contractAddress === selectedNft.contractAddress && activeNft.tokenId === selectedNft.tokenId
            )
        );

        const nftsToAddWithMarketplaceId = nftsToAdd.map(nftToAdd => {
            const collection = activeCollections.find(collection => collection.address === nftToAdd.contractAddress);
            return {
                ...nftToAdd,
                marketplaceId: collection.id
            };
        });
        try {
            await axios.post(base_uri + '/addToMarketplaceDB', { nfts: nftsToAddWithMarketplaceId }, {
                headers: {
                    api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m'
                }
            });
            toast.success('NFTs added to marketplace.')
        } catch (error) {
            toast.error('Error adding NFTs to marketplace.');
        }
        await loadMarketplace()
    };

    const removeNfts = async () => {
        const nftsToRemove = selectedNfts.filter(selectedNft =>
            activeNfts.some(activeNft =>
                activeNft.contractAddress === selectedNft.contractAddress && activeNft.tokenId === selectedNft.tokenId
            )
        );

        const nftsToRemovePayload = nftsToRemove.map(nft => ({
            id: nft.id,
            marketplaceId: nft.marketplaceId
        }));
    
        console.log('NFTs to Remove:', nftsToRemovePayload);
        try {
            await axios.post(base_uri + '/removeFromMarketplaceDB', { nfts: nftsToRemovePayload }, {
                headers: {
                    api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m'
                }
            });
            toast.success('NFTs removed from marketplace.')
        } catch (error) {
            toast.error('Error removing NFTs from marketplace.');
        }
        await loadMarketplace()
    };

    const toggleSelectNft = (nft) => {
        setSelectedNfts(prevSelectedNfts => {
            const isSelected = prevSelectedNfts.some(selectedNft => selectedNft.contractAddress === nft.contractAddress && selectedNft.tokenId === nft.tokenId);
            if (isSelected) {
                return prevSelectedNfts.filter(selectedNft => !(selectedNft.contractAddress === nft.contractAddress && selectedNft.tokenId === nft.tokenId));
            } else {
                return [...prevSelectedNfts, nft];
            }
        });
    };

    const toggleCollection = (collectionName) => {
        setOpenCollections(prevOpenCollections => ({
            ...prevOpenCollections,
            [collectionName]: !prevOpenCollections[collectionName]
        }));
    };

    const groupedNfts = nfts.reduce((acc, nft) => {
        if (!acc[nft.collectionName]) {
            acc[nft.collectionName] = [];
        }
        acc[nft.collectionName].push(nft);
        return acc;
    }, {});


    return !loaded ? null : (
        <div>
            <Header />
            <div className="nft-container">
                <h1>NFTs</h1>
                <div className="nft-collections">
                    {Object.keys(groupedNfts).map((collectionName, index) => (
                        <div key={index}>
                            <div className="collection-header" onClick={() => toggleCollection(collectionName)}>
                                <h2>{collectionName}</h2>
                            </div>
                            {openCollections[collectionName] && (
                                <div className="nft-grid">
                                    {groupedNfts[collectionName].map((nft, idx) => (
                                        <div
                                            className={`nft-card ${selectedNfts.some(selectedNft => selectedNft.contractAddress === nft.contractAddress && selectedNft.tokenId === nft.tokenId) ? 'selected' : ''}`}
                                            key={idx}
                                            onClick={() => toggleSelectNft(nft)}
                                        >
                                            <img src={nft.imageUrl} alt={`NFT ${nft.tokenId}`} className="nft-image" />
                                            <div className="nft-info">
                                                <p>Token ID: {nft.tokenId}</p>
                                                <p>Status: {isActive(nft.contractAddress, nft.tokenId) ? 'Active' : 'Inactive'}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {selectedNfts.length > 0 && (
                    <div className="action-buttons">
                        <button onClick={addNfts}>Add NFTs</button>
                        <button onClick={removeNfts}>Remove NFTs</button>
                    </div>
                )}
            </div>
        </div>
    );

}

export default Update